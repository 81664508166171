<template>
  <div class="button"><slot></slot></div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .button{
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    border-bottom: 2px solid #ffffff;
    transition: 0.3s;
    cursor: pointer;
  }
  .button:hover{
    border-color: transparent;
  }
</style>