<template>
  <yandex-map
      :coords="[55.796129, 49.106414]"
      :zoom="8"
      :scroll-zoom="false"
  >
    <ymap-marker
        v-for="project in projects"
        :key="project.id"
        marker-id="project.id"
        :coords="project.map.split(',')"
        :balloon-template="balloonTemplate(project)"
    />
  </yandex-map>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
export default {
  components: { yandexMap, ymapMarker },
  props: {
    projects: {
      type: Array,
      required: true,
    }
  },
  methods: {
    balloonTemplate(project) {
      return `
        <a href="/realised/${project.id}">
            <h5>${project.name}</h5>
            <img src="${project.images[0]}" height="300" width="300">
        </a>
      `
    }
  },
  mounted() {
  }
}
</script>

<style>
.ymap-container{
  height: 500px;
  border-radius: 7px;
  overflow: hidden;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .ymap-container{
    height: 300px;
  }
}
</style>