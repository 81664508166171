<template>
  <input type="text" class="input" @input="$event.target.value = $event.target.maxLength > 0 ? $event.target.value.slice(0, $event.target.maxLength) : $event.target.value">
</template>

<script>
export default {
}
</script>

<style scoped>
.input{
  height: 50px;
  width: 100%;
  padding: 10px 30px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 7px;
  border: 2px solid #D9D9D9;
  margin-bottom: 10px;
}
</style>