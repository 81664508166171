<template>
  <div class="section stats">
    <div class="container">
      <main-title>Статистика</main-title>
      <div class="stats__wrap">
        <stat-item v-for="(stat, key) in stats" :key="key" :stat="stat"/>
      </div>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/text/MainTitle";
import StatItem from "@/components/others/StatItem";
export default {
  components: {StatItem, MainTitle},
  data() {
    return {
      stats: [
        {num: 10, text: 'Лет на <br>рынке'},
        {num: 16, text: 'Регионов <br>строительства'},
        {num: 386, text: 'Объектов <br>построено'},
        {num: 1357, text: 'Проектов <br>разработано'},
      ]
    }
  }
}
</script>

<style scoped>
  .stats__wrap{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media (max-width: 768px) {
    .stats__wrap{
      flex-wrap: wrap;
    }
  }
</style>