<template>
  <div class="title"><slot></slot></div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.title{
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}
</style>