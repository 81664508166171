<template>
  <div class="section">
    <div class="container">
      <main-title>{{title}}</main-title>
      <projects-carousel :projects="projects"/>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/text/MainTitle";
import ProjectsCarousel from "@/components/carousel/ProjectsCarousel";
import {getProjects} from "@/api";

export default {
  components: {ProjectsCarousel, MainTitle},
  data() {
    return {
      projects: []
    }
  },
  props: {
    title: {
      type: String,
      default: 'Проекты домов',
      required: false
    }
  },
  async mounted() {
    this.projects = await getProjects();
  }
}
</script>

<style scoped>

</style>