<template>
  <base-modal :show="show" @update:show="$emit('update:show', false)">
    <modal-title>{{ title }}</modal-title>
    <modal-text>{{ text }}</modal-text>
    <contact-form @sendForm="sendForm"/>
  </base-modal>
  <success-modal v-model:show="successModal"/>
  <error-modal v-model:show="errorModal"/>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import ModalTitle from "@/components/text/ModalTitle";
import ModalText from "@/components/text/ModalText";
import ContactForm from "@/components/forms/ContactForm";
import SuccessModal from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/ErrorModal";
import {sendForm} from "@/api";

export default {
  components: {ErrorModal, SuccessModal, BaseModal, ModalTitle, ModalText, ContactForm},
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: false,
      default: 'Заполните форму, и мы свяжемся с вами в ближайшее время'
    }
  },
  data() {
    return {
      successModal: false,
      errorModal: false
    }
  },
  methods: {
    async sendForm(name, phone) {
      const result = await sendForm(name, phone, this.title, window.location.href);
      this.$emit('update:show', false);
      if(!result) return this.errorModal = true;
      return this.successModal = true;
    }
  }
}
</script>

<style scoped>

</style>