<template>
  <base-modal :show="show" @update:show="$emit('update:show', false)">
    <textarea ref="realisedName" cols="50" rows="5" style="border: 2px solid"></textarea>
    <input type="file" ref="realisedImages" name="image" accept="image/png, image/jpeg" multiple style="border: 2px solid; margin-top: 10; margin-bottom: 10px;">
    <select name="" id="" ref="realisedType" style="border: 2px solid; margin-top: 10; margin-bottom: 10px;">
      <option value="1" selected>Проф брус</option>
      <option value="2">Оцил бревно</option>
      <option value="3">Клееный брус</option>
      <option value="4">Баня</option>
    </select>
    <the-button @click="addRealised">Сохранить</the-button>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import BaseInput from "@/components/inputs/BaseInput";
import TheButton from "@/components/buttons/TheButton";
import {addRealised} from "@/api";
export default {
  components: {TheButton, BaseInput, BaseModal},
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    async addRealised() {
      await addRealised(+this.$refs.realisedType.value, this.$refs.realisedName.value, this.$refs.realisedImages.files);
      location.reload();
    }
  }
}
</script>

<style scoped>

</style>