<template>
  <base-modal :show="show" @update:show="$emit('update:show', false)">
    <modal-title>Произошла ошибка</modal-title>
    <modal-text>Попробуйте позвонить по номеру, либо отправить заявку позже</modal-text>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import ModalTitle from "@/components/text/ModalTitle";
import ModalText from "@/components/text/ModalText";
export default {
  components: {ModalText, ModalTitle, BaseModal},
  props: {
    show: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>

</style>