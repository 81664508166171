<template>
  <a :href="'/project/' + project.id" class="project">
    <the-image :url="project.image" :height="600" class="project__image" :class="mirrored ? 'project__order_second' : 'project__order_first'"/>
    <div class="project__content__wrap" :style="{backgroundImage: `url(${project.image})`}"  :class="mirrored ? 'project__order_first' : 'project__order_second'">
      <div class="project__content">
        <div class="project__title">{{project.name}}</div>
        <dotted-list :list="project.params" white class="project__params"/>
        <the-button class="project__button">Подробнее о проекте</the-button>
      </div>
    </div>
  </a>
</template>

<script>
import DottedList from "@/components/lists/DottedList";
import TheButton from "@/components/buttons/TheButton";
import TheImage from "@/components/images/TheImage";
export default {
  components: {TheImage, TheButton, DottedList},
  props: {
    project: {
      type: Object,
      required: true
    },
    mirrored: {
      type: Boolean,
      default: false,
      required: false
    }
  }
}
</script>

<style scoped>
  .project{
    height: 600px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-end;
    border-radius: 7px;
    overflow: hidden;
    text-decoration: none;
    cursor: pointer;
  }
  .project__content__wrap{
    min-height: 100%;
    min-width: 470px;
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
  }
  .project__content{
    min-height: 100%;
    padding: 120px 75px 0;
    background-color: rgba(0, 0, 0, 0.85);
  }
  .project__title{
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 50px;
    color: #fff;
  }
  .project__params{
    margin-bottom: 70px;
  }
  .project__order_first{
    order: 1;
    border-radius: 7px 0 0 7px;
  }
  .project__order_second{
    order: 2;
  }

  @media (max-width: 768px) {
    .project{
      height: auto;
      display: block;
    }
    .project__content{
      padding: 40px;
    }
    .project__content__wrap{
      min-width: 100%;
    }
    .project__params{
      display: none;
    }
    .project__title{
      text-align: center;
      font-size: 24px;
      margin-bottom: 30px;
    }
    .project__button{
      margin: 0 auto;
    }
  }
</style>
<style>
.project__image img{
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.project__image.project__order_second img {
  border-radius: 0 7px 7px 0;
}

@media (max-width: 768px) {
  .project__image img{
    height: 300px !important;
    border-radius: 7px 7px 0 0 !important;
  }
}
</style>