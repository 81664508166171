<template>
  <large-button class="outline-button"><slot></slot></large-button>
</template>

<script>
import LargeButton from "@/components/buttons/LargeButton";
export default {
  components: {LargeButton}
}
</script>

<style scoped>
  .outline-button{
    border: 3px solid #66C15E;
    background-color: transparent;
    color: #000;
  }
  .outline-button:hover{
    background-color: #66C15E;
    color: #fff;
    opacity: 1;
  }
</style>