<template>
  <div class="item">
    <div class="item__image" :style="{backgroundImage: `url(${item.image})`}"></div>
    <div class="item__text">{{item.text}}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
  .item{
    display: flex;
    align-items: center;
    margin-bottom: 45px;
  }
  .item__image{
    height: 100px;
    min-width: 100px;
    background-size: 70px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 45px;
    background-color: #66C15E;
    border-radius: 7px;
    box-shadow: 0px 0px 7px 0px #66C15E;
  }
  .item__text{
    font-size: 22px;
    font-weight: 500;
  }
  
  @media (max-width: 768px) {
    .item__image{
      height: 90px;
      min-width: 90px;
      background-size: 60px;
      margin-right: 20px;
    }
    .item__text{
      font-size: 18px;
    }
  }
</style>