<template>
  <div class="notice"><slot></slot></div>
</template>

<script>
export default {

}
</script>

<style>
  .notice{
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    line-height: 1.3;
  }
  .notice i{
    border-bottom: 2px solid #66C15E;
  }
</style>