<template>
  <div class="text"><slot></slot></div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .text{
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
  }
</style>