<template>
  <div class="section project" v-if="Object.keys(project).length > 0">
    <main-title>{{project.name}}</main-title>
    <project-page-carousel :images="project.images"/>
    <div class="container">
      <div class="project__actions">
        <project-params :params="getProjectParams(project)"/>
        <biggest-button @click="modal = true" class="project__button">Рассчитать стоимость</biggest-button>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="container">
      <div class="section__row">
        <div class="section__column" v-for="(plan, key) in project.plans" :key="key">
          <main-title>План {{key+1}} этажа</main-title>
          <the-image :url="plan" :height="600" contain class="project__plan"/>
        </div>
      </div>
    </div>
  </div>
  <asking-block
      title="Хотите внести свои изменения в планировку?"
      text="Оставьте заявку, и мы разработаем для вас индивидуальный проект"
      button-text="Изменить планировку"
  />
  <about-tech/>
  <house-projects title="Другие проекты"/>
  <contact-modal title="Рассчитать стоимость" text="Заполните форму, и мы пришлем вам рассчет стоимости проекта" v-model:show="modal"/>
</template>

<script>
import {getProjectById} from "@/api";
import MainTitle from "@/components/text/MainTitle";
import ProjectPageCarousel from "@/components/carousel/ProjectPageCarousel";
import ProjectParams from "@/components/lists/ProjectParams";
import LargeButton from "@/components/buttons/LargeButton";
import TheImage from "@/components/images/TheImage";
import AskingBlock from "@/blocks/AskingBlock";
import BiggestButton from "@/components/buttons/BiggestButton";
import AboutMaterial from "@/blocks/AboutMaterial";
import AboutTech from "@/blocks/AboutTech";
import HouseProjects from "@/blocks/HouseProjects";
import ContactModal from "@/components/modals/ContactModal";
import {useMeta} from "vue-meta";
export default {
  components: {
    HouseProjects,
    AboutTech,
    AboutMaterial,
    BiggestButton, AskingBlock, TheImage, LargeButton, ProjectParams, ProjectPageCarousel, MainTitle, ContactModal
  },
  setup() {
    useMeta({
      title: `Проект деревянного дома №${window.location.href.replace(/[^0-9]/g,"")} | Барс Дом`,
      description: `Проект деревянного дома №${window.location.href.replace(/[^0-9]/g,"")} с удобной планировной грамотным использованием площади. Заказать смету проекта.`,
    })
  },
  data() {
    return {
      project: {},
      modal: false
    }
  },
  methods: {
    getProjectParams(project) {
      const paramsKeys = {
        square: 'Площадь',
        rooms: 'Комнат',
        baths: 'Санузлов',
        balcony: 'Балконов',
        terrace: 'Террас',
        wardrobe: 'Гардеробов'
      }
      const result = []
      for (const k of Object.keys(project)) {
        if(paramsKeys[k] !== undefined && project[k] !== '') result.push({num: project[k], text: paramsKeys[k]});
      }
      return result;
    }
  },
  async mounted() {
    this.project = await getProjectById(this.$route.params.id);
  }
}
</script>

<style scoped>
.project__actions{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
}
.project__plan{
  border: 2px solid #66C15E;
  border-radius: 7px;
}
@media (max-width: 768px) {
  .project__actions{
    display: block;
    margin-top: 40px;
  }
  .project__button{
    margin: 20px auto 0;
    width: 100%;
  }
  .section__row{
    display: block;
  }
  .section__column{
    margin: 0;
  }
  .project__plan{
    margin-bottom: 40px;
  }
}
</style>
<style>
@media (max-width: 768px) {
  .project__plan img{
    height: 400px !important;
  }
}
</style>