<template>
  <a :href="'/realised/' + project.id" class="rd">
    <the-image :url="project.images[0]" class="rd__image"></the-image>
    <div class="rd__content">
      <div class="rd__name rd__name__min">{{project.name}}</div>
      <the-button class="rd__button" full>Открыть</the-button>
    </div>
  </a>
</template>

<script>
import TheImage from "@/components/images/TheImage";
import LargeButton from "@/components/buttons/LargeButton";
import TheButton from "@/components/buttons/TheButton";
export default {
  components: {TheButton, LargeButton, TheImage},
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.rd{
  border-radius: 7px;
  /*background-color: #22435D;*/
  background-color: #f5f5f5;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  display: block;
}
.rd__content{
  padding: 30px;
}
.rd__name{
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  height: 57px;
  color: #000;
}
.rd__button{
  margin-top: 20px;
}
@media (max-width: 768px) {
  .rd__content{
    padding: 10px 5px;
  }
  .rd__name__min{
    font-size: 12px !important;
    font-weight: 600;
    height: 65px;
  }
  .rd__button{
    font-size: 14px;
    height: 40px;
    padding: 10px;
  }
}
</style>

<style>
.rd__image img{
  min-height: 500px;
  border-radius: 7px 7px 0 0 !important;
}
@media (max-width: 768px) {
  .rd__image img{
    height: 200px !important;
    min-height: 200px !important;
  }
}
</style>