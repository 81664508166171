<template>
  <div class="section ipoteka">
    <div class="container">
      <div class="section__row">
        <div class="section__column">
          <the-image :url="require('@/assets/img/ipoteka2.jpg')" class="ipoteka__image"/>
          <large-button full @click="modal = true">Получить ипотечный рассчет</large-button>
        </div>
        <div class="section__column">
          <main-title align="left" class="ipoteka__title">Постройте дом мечты в ипотеку</main-title>
          <main-text align="left" class="ipoteka__text">
            Мы являемся аккредитованным застройщиком банков Сбербанк и Дом.рф
          </main-text>
<!--          <div class="ipoteka__banks">-->
<!--            <the-image :url="require('@/assets/img/sber.jpg')" class="ipoteka__bank" contain/>-->
<!--            <the-image :url="require('@/assets/img/domrf.jpg')" class="ipoteka__bank" contain/>-->
<!--          </div>-->

        <lined-list :list="[
            {title: 'Ипотечное кредитование от 5,3%', text: 'Оформляем ипотеку под максимально низкий процент'},
            {title: '95% одобрения', text: 'Помогаем с одобрением ипотеки на строительство дома'},
            {title: 'Рассрочка от компании', text: 'Предоставляем рассрочку на индивидуальных условиях'}
        ]"/>
        </div>
      </div>
    </div>
  </div>
  <contact-modal title="Получить ипотечный рассчет" text="Заполните форму, и мы свяжемся с вами для рассчета" v-model:show="modal"/>
</template>

<script>
import MainTitle from "@/components/text/MainTitle";
import TheImage from "@/components/images/TheImage";
import MainText from "@/components/text/MainText";
import LargeButton from "@/components/buttons/LargeButton";
import LinedList from "@/components/lists/LinedList";
import ContactModal from "@/components/modals/ContactModal";
export default {
  components: {LinedList, LargeButton, MainText, TheImage, MainTitle, ContactModal},
  data() {
    return {
      modal: false
    }
  }
}
</script>

<style scoped>
  .ipoteka__text{
    margin-bottom: 45px;
  }
  .ipoteka__banks{
    display: flex;
    margin: 60px 0;
  }
  .ipoteka__bank{
    max-height: 60px;
    max-width: 200px;
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    .section__row{
      flex-direction: column;
    }
    .section__column{
      margin: 0;
    }
    .section__column:first-child{
      order: 2;
    }
    .section__column:last-child{
      order: 1;
    }
    .ipoteka__title{
      text-align: center;
    }
    .ipoteka__text{
      text-align: center;
    }
  }
</style>
<style>
.ipoteka__image img{
  min-height: 500px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .ipoteka__image img{
    min-height: 300px;
    margin-top: 20px;
  }
}
</style>