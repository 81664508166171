<template>
  <div class="list">
    <div
        v-for="(listItem, key) in list"
        :key="key"
        class="list__item">
      <div class="list__item__dot">
        <div class="list__item__dot-inside"></div>
      </div>
      <div class="list__item__text" :class="{list__item__text_white: white}">{{listItem}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      required: true,
      type: Array
    },
    white: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.list__item{
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
.list__item:last-child{
  margin-bottom: 0;
}
.list__item__text{
  font-size: 22px;
  line-height: 1.1;
  font-weight: 500;
}
.list__item__text_white{
  color: #fff;
}
.list__item__dot{
  height: 30px;
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid #66C15E;
  box-shadow: 0px 0px 8px 3px #66C15E;
  margin-right: 30px;
}
.list__item__dot-inside{
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: #66C15E;
}

@media (max-width: 768px) {
  .list__item__text{
    font-size: 18px;
  }
}
</style>