import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload'
import { createMetaManager } from 'vue-meta'
//import store from './store'

const app = createApp(App);
    app
    //.use(store)
    .use(router)
    .use(VueLazyload)
    .use(createMetaManager())

await router.isReady();
app.mount('#app');
