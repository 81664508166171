<template>
  <div class="section">
    <video-banner class="advantages" :video="require('@/assets/video/about.mp4')" :poster="require('@/assets/video/production-clip-poster.jpg')" :opacity="0.5">
      <div class="container advantages__container">
        <div>
          <main-title white class="advantages__title">Наши преимущества</main-title>
          <main-text white class="advantages__text">Наша главная цель – построить для заказчика надежный и  уютный дом, который прослужит долгие годы. Для этого мы берем на себя все этапы, включая производство древесины</main-text>
        </div>
      </div>
    </video-banner>
  </div>
</template>

<script>
import VideoBanner from "@/components/video/VideoBanner";
import MainTitle from "@/components/text/MainTitle";
import MainText from "@/components/text/MainText";
export default {
  components: {MainText, MainTitle, VideoBanner}
}
</script>

<style scoped>
  .advantages{
    height: 600px;
  }
  .advantages__text{
    width: 1100px;
    margin: 0 auto;
  }
  .advantages__container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
  }
  
  @media (max-width: 768px) {
    .advantages{
      height: 500px;
    }
    .advantages__title{
      margin-bottom: 45px;
    }
    .advantages__text{
      width: 100%;
      text-align: center;
      font-size: 16px;
    }
  }
</style>