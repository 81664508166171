<template>
  <first-block/>
  <about-tech/>
  <our-advantages/>
  <house-projects/>
  <about-credit/>
  <realised-projects/>
  <the-stats/>
  <about-material/>
  <about-production/>
</template>

<style>
</style>
<script>
import FirstBlock from "@/blocks/FirstBlock";
import AboutTech from "@/blocks/AboutTech";
import OurAdvantages from "@/blocks/OurAdvantages";
import HouseProjects from "@/blocks/HouseProjects";
import AboutCredit from "@/blocks/AboutCredit";
import TheStats from "@/blocks/TheStats";
import AboutMaterial from "@/blocks/AboutMaterial";
import AboutProduction from "@/blocks/AboutProduction";
import RealisedProjects from "@/blocks/RealisedProjects";
import { useMeta } from 'vue-meta'
export default {
  components: {
    RealisedProjects,
    AboutProduction,
    AboutMaterial, TheStats, AboutCredit, HouseProjects, OurAdvantages, AboutTech, FirstBlock
  },
  setup () {
    useMeta({
      title: 'Строительство деревянных домов | Барс Дом',
      description: 'Строительство деревянных домов в Казани и по всей России от производителя. Производство бруса, оцилиндрованного бревна, клееного бруса. Проектирование домов.',
    })
  }
}
</script>