<template>
  <the-button class="button-large"><slot></slot></the-button>
</template>

<script>
import TheButton from "@/components/buttons/TheButton";
export default {
  components: {TheButton}
}
</script>

<style scoped>
  .button-large{
    height: 60px;
  }
</style>