<template>
  <the-button class="button-large"><slot></slot></the-button>
</template>

<script>
import TheButton from "@/components/buttons/TheButton";
export default {
  components: {TheButton}
}
</script>

<style scoped>
.button-large{
  height: 80px;
  font-size: 20px;
  padding: 0 50px;
}
@media (max-width: 768px) {
  .button-large{
    height: 60px;
    font-size: 18px;
  }
}
</style>