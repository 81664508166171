<template>
  <div class="text" :class="{text_left: align === 'left', text_white: white}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      required: false,
      default: 'center'
    },
    white: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>
.text{
  font-size: 22px;
  line-height: 1.4;
  font-weight: 400;
  text-align: center;
}
.text_left{
  text-align: left;
}
.text_white{
  color: #fff;
}
@media (max-width: 768px) {
  .text{
    font-size: 18px;
  }
}
</style>