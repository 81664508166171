<template>
  <div class="section">
    <div class="container">
      <div class="price__text__wrap">
        <div class="price__text">
          Цены обновлены 20.08.2023
        </div>
      </div>
      <main-title class="price__title">Профилированный брус (6м)</main-title>
      <div class="table">
        <div class="table__row">
          <div class="table__col table__col_h">
            <div class="table__text table__text_h">Ед. изм</div>
          </div>
          <div class="table__col table__col_h">
            <div class="table__text table__text_h">Сечение бруса</div>
          </div>
          <div class="table__col table__col_h">
            <div class="table__text table__text_h">Стоимость за м3</div>
          </div>
          <div class="table__col table__col_h">
            <div class="table__text table__text_h">Изображение</div>
          </div>
        </div>
        <div class="table__line">
          <div class="table__text table__text_title">
            Брус естественной влажности
          </div>
        </div>
        <div class="table__row">
          <div class="table__col">
            <div class="table__text">
              м3
            </div>
            <div class="table__text">
              м3
            </div>
            <div class="table__text">
              м3
            </div>
            <div class="table__text">
              м3
            </div>
          </div>
          <div class="table__col">
            <div class="table__text">
              95x145
            </div>
            <div class="table__text">
              145x145
            </div>
            <div class="table__text">
              195x14
            </div>
            <div class="table__text">
              195x195
            </div>
          </div>
          <div class="table__col">
            <div class="table__text">
              21 500 руб
            </div>
            <div class="table__text">
              21 500 руб
            </div>
            <div class="table__text">
              21 500 руб
            </div>
            <div class="table__text">
              21 500 руб
            </div>
          </div>
          <div class="table__col">
            <div class="table__image" :style="{backgroundImage: `url(${require('@/assets/img/profilirovanniy-brus.jpg')})`}"></div>
          </div>
        </div>
        <div class="table__line">
          <div class="table__text table__text_title">
            Брус камерной сушки
          </div>
        </div>
        <div class="table__row">
          <div class="table__col">
            <div class="table__text">
              м3
            </div>
            <div class="table__text">
              м3
            </div>
            <div class="table__text">
              м3
            </div>
            <div class="table__text">
              м3
            </div>
          </div>
          <div class="table__col">
            <div class="table__text">
              95x145
            </div>
            <div class="table__text">
              145x145
            </div>
            <div class="table__text">
              195x14
            </div>
            <div class="table__text">
              195x195
            </div>
          </div>
          <div class="table__col">
            <div class="table__text">
              24 000 руб
            </div>
            <div class="table__text">
              24 000 руб
            </div>
            <div class="table__text">
              24 000 руб
            </div>
            <div class="table__text">
              24 000 руб
            </div>
          </div>
          <div class="table__col">
            <div class="table__image" :style="{backgroundImage: `url(${require('@/assets/img/profilirovanniy-brus.jpg')})`}"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="container">
      <main-title class="price__title">Оцилиндрованное бревно (6м)</main-title>
      <div class="table">
        <div class="table__row">
          <div class="table__col table__col_h">
            <div class="table__text table__text_h">Ед. изм</div>
          </div>
          <div class="table__col table__col_h">
            <div class="table__text table__text_h">Диаметр бревна</div>
          </div>
          <div class="table__col table__col_h">
            <div class="table__text table__text_h">Стоимость за м3</div>
          </div>
          <div class="table__col table__col_h">
            <div class="table__text table__text_h">Изображение</div>
          </div>
        </div>
        <div class="table__line">
          <div class="table__text table__text_title">
              Оцилиндрованное бревно
          </div>
        </div>
        <div class="table__row">
          <div class="table__col">
            <div class="table__text">м3</div>
            <div class="table__text">м3</div>
            <div class="table__text">м3</div>
            <div class="table__text">м3</div>
            <div class="table__text">м3</div>
            <div class="table__text">м3</div>
            <div class="table__text">м3</div>
          </div>
          <div class="table__col">
            <div class="table__text">180</div>
            <div class="table__text">200</div>
            <div class="table__text">220</div>
            <div class="table__text">240</div>
            <div class="table__text">260</div>
            <div class="table__text">280</div>
            <div class="table__text">300</div>
          </div>
          <div class="table__col">
            <div class="table__text">13 500 руб</div>
            <div class="table__text">14 500 руб</div>
            <div class="table__text">14 500 руб</div>
            <div class="table__text">14 500 руб</div>
            <div class="table__text">14 500 руб</div>
            <div class="table__text">14 500 руб</div>
            <div class="table__text">14 500 руб</div>
          </div>
          <div class="table__col">
            <div class="table__image" :style="{backgroundImage: `url(${require('@/assets/img/ocilindrovannoe.jpeg')})`}"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="container">
      <main-title class="price__title">
        Клееный брус (руб/м3) не срощенный (6м) <br>
        Срощенный брус (12м) + 1300руб/м3
      </main-title>
      <div class="table">
        <div class="table__row">
          <div class="table__col table__col_h">
            <div class="table__text table__text_h">Высота (мм)</div>
          </div>
          <div class="table__col table__col_h">
            <div class="table__text table__text_h">Ширина (мм)</div>
          </div>
          <div class="table__col table__col_h">
            <div class="table__text table__text_h">Стоимость за м3</div>
          </div>
          <div class="table__col table__col_h">
            <div class="table__text table__text_h">Изображение</div>
          </div>
        </div>
        <div class="table__line">
          <div class="table__text table__text_title">
            Клееный брус
          </div>
        </div>
        <div class="table__row">
          <div class="table__col">
            <div class="table__text">
              140/185
            </div>
            <div class="table__text">
              140/185
            </div>
            <div class="table__text">
              140/185
            </div>
            <div class="table__text">
              275
            </div>
          </div>
          <div class="table__col">
            <div class="table__text">
              160/205/230
            </div>
            <div class="table__text">
              240
            </div>
            <div class="table__text">
              125
            </div>
            <div class="table__text">
              160/202/244
            </div>
          </div>
          <div class="table__col">
            <div class="table__text">
              39 000 руб
            </div>
            <div class="table__text">
              39 000 руб
            </div>
            <div class="table__text">
              39 000 руб
            </div>
            <div class="table__text">
              43 000 руб
            </div>
          </div>
          <div class="table__col">
            <div class="table__image" :style="{backgroundImage: `url(${require('@/assets/img/kleeniy-brus.jpg')})`}"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/text/MainTitle";
import TheImage from "@/components/images/TheImage";
import MainText from "@/components/text/MainText";
import {useMeta} from "vue-meta";
export default {
  components: {MainText, TheImage, MainTitle},
  setup () {
    useMeta({
      title: 'Прайс-лист | Барс Дом',
      description: 'Стоимость материалов: профилированный брус, оцилиндрованное бревно, клееный брус',
    })
  }
}
</script>

<style scoped>
.price__text__wrap{
  display: flex;
  justify-content: center;
}
.price__text{
  margin-bottom: 60px;
  color: red;
  font-size: 20px;
  font-weight: 600;
  padding: 15px 25px;
  border-radius: 7px;
  border: 2px solid red;
  display: inline;
}
.table{
  border-radius: 7px;
  overflow: hidden;
  width: 1200px;
  margin: 0 auto;
  border: 1px solid #efefef;
}
.table__row{
  display: flex;
}
.table__col{
  width: 100%;
  border: 1px solid #efefef;
}
.table__col_h{
  background-color: #f5f5f5;
}
.table__text{
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  padding: 20px;
  border-bottom: 2px solid #efefef;
}
.table__text_title{
  color: #fff;
  text-align: center;
}
.table__text:last-child{
  border-bottom: none;
}
.table__image{
  width: 100%;
  height: 100%;
  min-height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.table__line{
  background-color: #53a24c;
  border: 1px solid #efefef;
}

@media (max-width: 768px) {
  .table{
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
  }
  .table__text{
    font-size: 12px;
    padding: 5px;
  }
  .table__image{
    min-height: 120px;
  }
  .price__title{
    font-size: 18px;
  }
  .price__text{
    font-size: 18px;
    padding: 10px 15px;
  }
}
</style>