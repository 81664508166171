<template>
  <base-input placeholder="Введите телефон" type="Number" maxlength="12"/>
</template>

<script>
import BaseInput from "@/components/inputs/BaseInput";
export default {
  components: {BaseInput}
}
</script>

<style scoped>
</style>