<template>

</template>

<script>
import {useMeta} from "vue-meta";

export default {
  setup () {
    useMeta({
      title: 'Контакты | Барс Дом',
      description: 'Контакты и местоположение офиса и производства компании Барс Дом',
    })
  }
}
</script>

<style scoped>

</style>