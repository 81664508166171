<template>
  <div class="image__wrap">
    <img
        class="image"
        v-lazy="url"
        :class="contain && 'image_contain'"
        :style="{height: `${height}px`}"
    >
    <div class="image__overlay"></div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      required: true,
      type: String,
    },
    height: {
      required: false,
      type: Number,
      default: 300
    },
    contain: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
  /*.image{*/
  /*  background-size: cover;*/
  /*  background-position: center;*/
  /*  background-repeat: no-repeat;*/
  /*  width: 100%;*/
  /*  border-radius: 7px;*/
  /*}*/
  /*.image_contain{*/
  /*  background-size: contain;*/
  /*}*/

  .image {
    display: block;
    object-position: center;
    object-fit: cover;
    width: 100%;
    border-radius: 7px;
  }
  .image__wrap{
    position: relative;
  }
  .image__overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .image_contain{
    object-fit: contain;
  }
</style>