<template>
  <a :href="'/realised/' + project.id" class="realised">
    <the-image :url="project.images[0]" class="realised__image"></the-image>
    <div class="realised__content">
      <div class="realised__name">{{project.name}}</div>
      <large-button class="realised__button">Открыть</large-button>
    </div>
  </a>
</template>

<script>
import TheImage from "@/components/images/TheImage";
import LargeButton from "@/components/buttons/LargeButton";
export default {
  components: {LargeButton, TheImage},
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.realised{
  border-radius: 7px;
  background-color: #22435D;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  display: block;
}
.realised__content{
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.realised__name{
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}
.realised__button{
  min-width: 200px;
  margin-left: 20px;
}
</style>

<style>
.realised__image img{
  min-height: 500px;
  border-radius: 7px 7px 0 0 !important;
}
@media (max-width: 768px) {
  .realised__image img{
    min-height: 300px !important;
  }
  .realised__name{
    font-size: 16px !important;
  }
}
</style>