<!-- <template>
	<div class="section about">
		<div class="container">
			<div class="section__row">
				<div class="section__column">
					<div class="about__image__wrap">
						<the-image
							class="about__image"
							contain
							:url="require('@/assets/img/full-logo.svg')"
						/>
					</div>
				</div>
				<div class="section__column">
					<main-title align="left">О компании</main-title>
					<div class="about__text">
						Компания «Барс Дом» занимается производством оцилиндрованного
						бревна, клееного бруса, профилированного бруса и строительством
						домов более 10 лет.
						<br /><br />
						Мы строим надежные дома со вкусом, по индивидуальным и типовым
						проектам, с продуманными планировками. Наша компания может
						гарантировать вам надежный и долговечный дом благодаря качеству
						материалов собственного производства.
						<br /><br />
						Огромный опыт наших специалистов, отточенные технологии
						строительства и собственные наработки позволяют нам строить
						качественные коттеджи для загородной жизни.
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="section">
		<div class="container">
			<main-title>Наша команда</main-title>
			<team-carousel :team="team" />
		</div>
	</div>
	<the-stats />
</template>

<script>
import MainTitle from '@/components/text/MainTitle'
import MainText from '@/components/text/MainText'
import TheImage from '@/components/images/TheImage'
import TheStats from '@/blocks/TheStats'
import TeamCarousel from '@/components/carousel/TeamCarousel'
import { useMeta } from 'vue-meta'
export default {
	components: { TeamCarousel, TheStats, TheImage, MainTitle, MainText },
	setup() {
		useMeta({
			title: 'О компании | Барс Дом',
			description:
				'Компания «Барс Дом» занимается строительством деревянных домов и производством оцилиндрованного бревна, клееного бруса, профилированного бруса',
		})
	},
	data() {
		return {
			team: [
				{
					name: 'Сергей Орлов',
					role: 'Руководитель',
					image: require('@/assets/img/team1.jpeg'),
				},
				{
					name: 'Александр Ямщиков',
					role: 'Бригадир',
					image: require('@/assets/img/team2.jpeg'),
				},
        {
					name: 'Седов Николай',
					role: 'Бригадир',
					image: require('@/assets/img/team3.jpeg'),
				},
        {
					name: 'Роман Горностаев',
					role: 'Бригадир',
					image: require('@/assets/img/team4.jpeg'),
				},
        {
					name: 'Алексей Казаев',
					role: 'Сметчик',
					image: require('@/assets/img/team5.png'),
				},
        {
					name: 'Тимур Сарпов',
					role: 'Менеджер',
					image: require('@/assets/img/team6.jpeg'),
				},
        {
					name: 'Андрей Белоножкин',
					role: 'Специалист по отделочным работам',
					image: require('@/assets/img/team7.jpeg'),
				},
        {
					name: 'Александр Чугунов',
					role: 'Проектировщик',
					image: require('@/assets/img/team8.jpeg'),
				},
        {
					name: 'Шилов Олег',
					role: 'Проектировщик',
					image: require('@/assets/img/team9.jpeg'),
				},
        {
					name: 'Юля Сухова',
					role: 'Маркетолог PR',
					image: require('@/assets/img/team10.jpeg'),
				},
        {
					name: 'Денисова Леана',
					role: 'Маркетолог PR',
					image: require('@/assets/img/team11.jpeg'),
				},
			],
		}
	},
}
</script>

<style scoped>
.about__image__wrap {
	width: 100%;
	min-height: 100%;
	border-radius: 7px;
	background-color: #efefef;
	display: flex;
	justify-content: center;
	align-items: center;
}
.about__image {
	width: 50%;
	height: auto;
}
.about__text {
	font-size: 20px;
}

@media (max-width: 768px) {
	.section__row {
		flex-direction: column;
	}
	.section__column {
		margin: 0;
	}
	.section__column:first-child {
		order: 2;
	}
	.section__column:last-child {
		order: 1;
	}
	.about__image__wrap {
		margin-top: 40px;
	}
}
</style> -->

<template>
	<div class="section about">
		<div class="container">
			<div class="section__row">
				<div class="section__column">
					<div class="about__image__wrap">
						<the-image
							class="about__image"
							contain
							:url="require('@/assets/img/full-logo.svg')"
						/>
					</div>
				</div>
				<div class="section__column">
					<main-title align="left">О компании</main-title>
					<div class="about__text">
						Компания «Барс Дом» занимается производством оцилиндрованного
						бревна, клееного бруса, профилированного бруса и строительством
						домов более 10 лет.
						<br /><br />
						Мы строим надежные дома со вкусом, по индивидуальным и типовым
						проектам, с продуманными планировками. Наша компания может
						гарантировать вам надежный и долговечный дом благодаря качеству
						материалов собственного производства.
						<br /><br />
						Огромный опыт наших специалистов, отточенные технологии
						строительства и собственные наработки позволяют нам строить
						качественные коттеджи для загородной жизни.
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="section">
		<div class="container">
			<main-title>Наша команда</main-title>
			<team-cards :team="team" />
		</div>
	</div>
	<the-stats />
</template>

<script>
import MainTitle from '@/components/text/MainTitle'
import MainText from '@/components/text/MainText'
import TheImage from '@/components/images/TheImage'
import TheStats from '@/blocks/TheStats'
import TeamCards from '@/blocks/TeamCards'
import { useMeta } from 'vue-meta'

export default {
	components: { TeamCards, TheStats, TheImage, MainTitle, MainText },
	setup() {
		useMeta({
			title: 'О компании | Барс Дом',
			description:
				'Компания «Барс Дом» занимается строительством деревянных домов и производством оцилиндрованного бревна, клееного бруса, профилированного бруса',
		})
	},
	data() {
		return {
			team: [
				{
					name: 'Сергей Орлов',
					role: 'Руководитель',
					image: require('@/assets/img/team1.jpeg'),
				},
				{
					name: 'Александр Ямщиков',
					role: 'Бригадир',
					image: require('@/assets/img/team2.jpeg'),
				},
				{
					name: 'Седов Николай',
					role: 'Бригадир',
					image: require('@/assets/img/team3.jpeg'),
				},
				{
					name: 'Роман Горностаев',
					role: 'Бригадир',
					image: require('@/assets/img/team4.jpeg'),
				},
				{
					name: 'Алексей Казаев',
					role: 'Сметчик',
					image: require('@/assets/img/team5.png'),
				},
				{
					name: 'Тимур Сарпов',
					role: 'Менеджер',
					image: require('@/assets/img/team6.jpeg'),
				},
				{
					name: 'Андрей Белоножкин',
					role: 'Специалист по отделочным работам',
					image: require('@/assets/img/team7.jpeg'),
				},
				{
					name: 'Александр Чугунов',
					role: 'Проектировщик',
					image: require('@/assets/img/team8.jpeg'),
				},
				{
					name: 'Шилов Олег',
					role: 'Проектировщик',
					image: require('@/assets/img/team9.jpeg'),
				},
				{
					name: 'Юля Сухова',
					role: 'Маркетолог PR',
					image: require('@/assets/img/team10.jpeg'),
				},
				{
					name: 'Денисова Леана',
					role: 'Маркетолог PR',
					image: require('@/assets/img/team11.jpeg'),
				},
			],
		}
	},
}
</script>

<style scoped>
.about__image__wrap {
	width: 100%;
	min-height: 100%;
	border-radius: 7px;
	background-color: #efefef;
	display: flex;
	justify-content: center;
	align-items: center;
}
.about__image {
	width: 50%;
	height: auto;
}
.about__text {
	font-size: 20px;
}

@media (max-width: 768px) {
	.section__row {
		flex-direction: column;
	}
	.section__column {
		margin: 0;
	}
	.section__column:first-child {
		order: 2;
	}
	.section__column:last-child {
		order: 1;
	}
	.about__image__wrap {
		margin-top: 40px;
	}
}
</style>
