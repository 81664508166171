<template>
  <div class="list">
    <div class="list__item" v-for="(item, key) in list" :key="key">
      <div class="list__item__title">{{item.title}}</div>
      <div class="list__item__text">{{item.text}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.list__item{
  padding: 25px 0;
  border-top: 1px solid #000;
  display: flex;
  justify-content: space-between;
}
.list__item:last-child{
  border-bottom: 1px solid #000;
}
.list__item__title{
  font-size: 22px;
  font-weight: 500;
}
.list__item__text{
  font-size: 22px;
  font-weight: 700;
}
</style>