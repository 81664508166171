<template>
  <div class="section">
    <div class="container">
      <main-title>Проекты домов</main-title>
      <div class="project__container" v-for="(project, key) in projects" :key="project.id">
        <project-item :project="project" :mirrored="key % 2 !== 0"/>
      </div>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/text/MainTitle";
import {getProjects} from "@/api";
import ProjectItem from "@/components/others/ProjectItem";
import {useMeta} from "vue-meta";
export default {
  components: {ProjectItem, MainTitle},
  data() {
    return {
      projects: []
    }
  },
  setup() {
    useMeta({
      title: 'Проекты деревянных домов | Барс Дом',
      description: 'Проекты деревянных домов компании Барс Дом',
    })
  },
  async mounted() {
    this.projects = await getProjects();
  }
}
</script>

<style scoped>
.project__container{
  margin-bottom: 30px;
}
</style>