<template>
  <a href="/" class="header__logo"></a>
</template>

<script>
export default {

}
</script>

<style scoped>
.header__logo{
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  color: #8CA103;
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  background-image: url(@/assets/img/logo3.svg);
  height: 60px;
  width: 280px;
}
@media (max-width: 768px) {
  .header__logo{
    width: 255px;
    height: 52px;
  }
}
</style>