<template>
  <div class="section">
    <div class="container">
      <main-title>Построенные объекты</main-title>
<!--      <the-button @click="modal = true">Добавить проект</the-button>-->
      <realised-map :projects="projects"/>
      <div class="realised__wrap">
        <realised-small-item
            v-for="project in projects"
            :key="project.id"
            :project="project"
            class="realised__item"
        />
      </div>
    </div>
  </div>
<!--  <add-realised-modal v-model:show="modal" />-->
</template>

<script>
import RealisedProjects from "@/blocks/RealisedProjects";
import RealisedItem from "@/components/others/RealisedItem";
import {getRealisedProjects} from "@/api";
import MainTitle from "@/components/text/MainTitle";
import RealisedMap from "@/blocks/RealisedMap";
import RealisedSmallItem from "@/components/others/RealisedSmallItem";
import EditProductModal from "@/components/modals/AddRealisedModal";
import AddRealisedModal from "@/components/modals/AddRealisedModal";
import TheButton from "@/components/buttons/TheButton";
import {useMeta} from "vue-meta";
export default {
  components: {
    TheButton,
    AddRealisedModal,
    EditProductModal, RealisedSmallItem, RealisedMap, MainTitle, RealisedItem, RealisedProjects},
  data() {
    return {
      modal: false,
      projects: []
    }
  },
  setup() {
    useMeta({
      title: 'Реализованные объекты | Барс Дом',
      description: 'Реализованные объекты компании Барс Дом',
    })
  },
  async mounted() {
    this.projects = await getRealisedProjects();
  }
}
</script>

<style scoped>
.realised__wrap{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.realised__item{
  margin-bottom: 30px;
  width: 32%;
}

@media (max-width: 768px) {
  .realised__item{
    width: 48%;
  }
}
</style>