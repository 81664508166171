<template>
  <a :href="link" target="_blank" rel="nofollow" class="social"></a>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.social{
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 7px;
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
  transition: 0.3s;
  cursor: pointer;
  /*background-color: #a5a5a5;*/
  background-color: rgba(0, 0, 0, 0.7);
}
</style>
<style>
.social.whatsapp{
  background-image: url(@/assets/img/whatsapp.svg);
}
.social.whatsapp:hover{
  background-color: #66C15E;
}
.social.telegram{
  background-image: url(@/assets/img/telegram.svg);
}
.social.telegram:hover{
  background-color: #039BE5;
}
.social.instagram{
  background-image: url(@/assets/img/instagram.svg);
}
.social.instagram:hover{
  background-color: #E75B6C;
}
</style>