<template>
  <div class="swiper__wrap">
    <swiper
        :modules="modules"
        :space-between="50"
        :loop="true"
        :slides-per-view="3.3"
        :breakpoints="{ 300:{ slidesPerView: 2, centeredSlides: false, spaceBetween: 20 }, 900:{ slidesPerView: 3.3 } }"
        :centered-slides="true"
        :navigation="{
          prevEl: prev,
          nextEl: next,
        }"
    >
      <swiper-slide
          v-for="(project, key) in projects"
          :key="project.id"
          :virtualIndex="key"
      >
        <realised-small-item :project="project" class="realised__item"/>
      </swiper-slide>
    </swiper>
    <div ref="prev" class="swiper-button swiper-button_prev_full">
      <div class="swiper-button__arrow swiper-button__arrow_prev"></div>
    </div>
    <div ref="next" class="swiper-button swiper-button_next_full">
      <div class="swiper-button__arrow swiper-button__arrow_next"></div>
    </div>
  </div>

</template>

<script>
import { ref } from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation} from "swiper/modules";
import ProjectItem from "@/components/others/ProjectItem";
import TheImage from "@/components/images/TheImage";
import RealisedItem from "@/components/others/RealisedItem";
import RealisedSmallItem from "@/components/others/RealisedSmallItem";
export default {
  components: {RealisedSmallItem, RealisedItem, TheImage, Swiper, SwiperSlide},
  props: {
    projects: {
      type: Array,
      required: true
    }
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
      modules: [Navigation],
      prev,
      next,
    };
  },
}
</script>

<style scoped>
@media (max-width: 768px) {
  .swiper__wrap{
    margin-right: 15px;
    margin-left: 15px;
  }
}
</style>