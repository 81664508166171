<template>
  <div class="header">
    <div class="container header__wrap">
      <the-logo/>
<!--      <div class="header__text">Строительство домов из <br>дерева от производителя</div>-->
<!--      <div class="header__text">Офис: г. Казань, Пушкина 10, офис 1 <br>Производство: г. Киров, Слободской р-н</div>-->
      <div class="header__buttons">
        <social-button :link="c.whatsappLink" class="whatsapp"/>
        <social-button :link="c.telegramLink" class="telegram"/>
        <social-button :link="c.instagramLink" class="instagram"/>
        <the-button>Заказать звонок</the-button>
      </div>
    </div>
  </div>
  <the-navbar/>
</template>

<script>
import TheButton from "@/components/buttons/TheButton";
import TheNavbar from "@/layouts/TheNavbar";
import SocialButton from "@/components/buttons/SocialButton";
import TheLogo from "@/components/images/TheLogo";
import data from "@/data";
export default {
  components: {TheLogo, SocialButton, TheNavbar, TheButton},
  data() {
    return {
      c: data.contacts
    }
  },
}
</script>

<style scoped>
.header{
  padding: 10px 0;
  background-color: #fff;
}
.header__wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__text{
  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
}
.header__buttons{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>