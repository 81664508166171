<template>
  <div class="navbar">
    <div class="container navbar__wrap">
      <div class="navbar__items">
        <a href="/projects" class="navbar__link">Проекты</a>
        <a href="/production" class="navbar__link">Производство</a>
        <div href="/services" class="navbar__link">Услуги</div>
        <a href="/realised" class="navbar__link">Построенные объекты</a>
        <div href="/about" class="navbar__link">О компании</div>
        <a href="/contacts" class="navbar__link">Контакты</a>
      </div>
      <div class="navbar__phones">
        <a :href="c.firstPhoneLink" class="navbar__phone">{{c.firstPhone}}</a>
        <a :href="c.secondPhoneLink" class="navbar__phone">{{ c.secondPhone }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.navbar{
  /*background-color: #e5e5e5;*/
  background-color: rgba(0, 0, 0, 0.7);
  position: -webkit-sticky;
  position: sticky;
  z-index: 10001;
  top: 0;
}
.navbar__wrap{
  display: flex;
  justify-content: space-between;
}
.navbar__items{
  display: flex;
  align-items: center;
}
.navbar__link{
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  padding: 14px 0;
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  transition: 0.3s;
  cursor: pointer;
  margin-right: 25px;
  margin-left: 25px;
  color: #fff;
}
.navbar__link:last-child{
  margin-right: 0;
}
.navbar__link:first-child{
  margin-left: 0;
}
.navbar__link:hover{
  border-bottom-color: #66C15E;
}
.navbar__phones{
  display: flex;
  align-items: center;
}
.navbar__phone{
  font-weight: 600;
  font-size: 18px;
  margin: 0 20px;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
}
.navbar__phone:hover{
  color: #66C15E;
}
.navbar__phone:last-child{
  margin-right: 0;
}
.navbar__phone:first-child{
  margin-left: 0;
}
</style>