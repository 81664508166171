<template>
  <div class="list">
    <div class="list__item" v-for="(item, key) in list" :key="key">
      <div class="list__item__title">{{item.title}}</div>
      <div class="list__item__text">{{item.text}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.list__item{
  padding: 25px 0;
  border-top: 1px solid #000;
}
.list__item:last-child{
  border-bottom: 1px solid #000;
}
.list__item__title{
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}
.list__item__text{
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .list__item{
    padding: 20px 0;
  }
  .list__item__title{
    font-size: 20px;
  }
  .list__item__text{
    font-size: 18px;
  }
}
</style>