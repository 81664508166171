const data = {
    apiUrl: 'https://barsdom.ru/api/index.php',
    contacts: {
        firstPhone: '8 (912) 737-77-88',
        firstPhoneLink: 'tel: 89127377788',
        secondPhone: '8 (927) 036-65-88',
        secondPhoneLink: 'tel: 89270366588',
        email: 'barsdom43@yandex.ru',
        officeAddress: 'г. Казань, Адмиралтейская 3, корпус 1, кабинет 206',
        officeAddressLink: 'https://yandex.ru/map-widget/v1/?ll=49.040611%2C55.801414&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NjMwMDEzORJy0KDQvtGB0YHQuNGPLCDQoNC10YHQv9GD0LHQu9C40LrQsCDQotCw0YLQsNGA0YHRgtCw0L0sINCa0LDQt9Cw0L3RjCwg0JDQtNC80LjRgNCw0LvRgtC10LnRgdC60LDRjyDRg9C70LjRhtCwLCAz0LoxIgoNzDNEQhXgMV9C&z=14.26',
        productionAddress: 'г. Киров, Слободской р-н, д. Понизовье',
        productionAddressLink: 'https://yandex.ru/map-widget/v1/?ll=49.570865%2C58.582682&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1MzEwNDk4MBIY0KDQvtGB0YHQuNGPLCDQmtC40YDQvtCyIgoNDaxGQhUVampC&z=11.38',
        whatsappLink: 'https://wa.me/+79127377788',
        telegramLink: 'https://t.me/+79127377788',
        instagramLink: 'https://instagram.com/'
    }
}
export default data;