<template>
  <div class="section project" v-if="Object.keys(project).length > 0">
    <main-title class="project__title">{{project.name}}</main-title>
    <project-page-carousel :images="project.images" contain class="realised_carousel"/>
  </div>
  <div class="section" v-if="getProjectParams(project).length > 0">
    <div class="container">
      <div class="section__row">
        <div class="section__column">

          <callback-block/>
        </div>
        <div class="section__column">
          <lined-horisontal-list :list="getProjectParams(project)"/>
        </div>
      </div>
    </div>
  </div>
  <asking-block v-else title="Заинтересовал этот проект?" text="Оставьте заявку, и мы пришлем вам смету по данному проекту" button-text="Получить смету"/>
  <about-tech/>
  <realised-projects title="Другие построенные объекты"/>
</template>

<script>
import {getRealisedProjectById} from "@/api";
import MainTitle from "@/components/text/MainTitle";
import ProjectPageCarousel from "@/components/carousel/ProjectPageCarousel";
import AskingBlock from "@/blocks/AskingBlock";
import AboutTech from "@/blocks/AboutTech";
import LinedHorisontalList from "@/components/lists/LinedHorisontalList";
import CallbackBlock from "@/components/others/CallbackBlock";
import RealisedProjects from "@/blocks/RealisedProjects";
import {useMeta} from "vue-meta";
export default {
  components: {
    RealisedProjects,
    CallbackBlock,
    LinedHorisontalList,
    AboutTech, AskingBlock, ProjectPageCarousel, MainTitle},
  data() {
    return {
      project: {}
    }
  },
  setup() {
    useMeta({
      title: `Реализованный объект №${window.location.href.replace(/[^0-9]/g,"")} | Барс Дом`,
      description: `Реализованный объект №${window.location.href.replace(/[^0-9]/g,"")} компании Барс Дом`,
    })
  },
  methods: {
    getProjectParams(project) {
      const paramsKeys = {
        year: 'Год постройки',
        square: 'Площадь',
        material: 'Тип',
        size: 'Размер',
      }
      const result = []
      for (const k of Object.keys(project)) {
        if(paramsKeys[k] !== undefined && project[k] !== '') result.push({title: paramsKeys[k], text: project[k]});
      }
      return result;
    }
  },
  async mounted() {
    this.project = await getRealisedProjectById(this.$route.params.id);
    console.log(this.project);
  }
}
</script>

<style scoped>
  @media (max-width: 768px) {
    .project__title{
      font-size: 18px;
    }
  }
</style>