<template>
  <div class="dialog" v-show="show" @click="closeModal">
    <div class="dialog__content" @click.stop>
      <svg
          class="dialog__close-btn"
          @click="closeModal"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#2E2E2D">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <g id="Menu / Close_MD">
            <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="#2E2E2D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          </g>
        </g>
      </svg>
      <slot></slot>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false);
    }
  },
  watch: {
    show(value) {
      if(value === true) return document.body.style.overflowY = 'hidden';
      return document.body.style.overflowY = 'scroll';
    }
  }
}
</script>

<style scoped>
.dialog{
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  z-index: 10001;
}
.dialog__content{
  margin: 100px auto 0;
  background-color: #fff;
  min-height: 200px;
  height: auto;
  width: 500px;
  padding: 40px 60px;
  border-radius: 20px;
  position: relative;
}
.dialog__close-btn{
  position: absolute;
  right: 15px;
  top: 15px;
  width: 2.2em;
  height: 2.2em;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
}
.dialog__close-btn:hover{
  opacity: 0.7;
}

@media (max-width: 768px) {
  .dialog__content{
    width: 95%;
    padding: 40px;
  }
}
</style>