<template>
  <div class="list">
    <div
        class="list__item"
        v-for="(l, key) in list"
        :key="key"
    >
      <div class="list__image" :style="{backgroundImage: `url(${l.image})`}"></div>
      <div class="list__content">
        <div class="list__title">{{l.title}}</div>
        <div class="list__text">{{l.text}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import TheImage from "@/components/images/TheImage";
export default {
  components: {TheImage},
  props: {
    list: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.list{
  display: flex;
  justify-content: space-between;
}
.list__item{
  display: flex;
  align-items: center;
}
.list__content{
  max-width: 310px;
}
.list__image{
  min-width: 90px;
  max-width: 90px;
  height: 90px;
  margin-right: 20px;
  background-size: 70px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #66C15E;
  border: 2px solid #fff;
  box-shadow: 0px 0px 10px 4px #66C15E;
  border-radius: 7px;
}
.list__title{
  font-size: 17px;
  line-height: 1.3;
  font-weight: 700;
  margin-bottom: 10px;
}
.list__text{
  font-size: 17px;
  line-height: 1.3;
  font-weight: 500;
}

@media (max-width: 768px) {
  .list{
    display: block;
  }
  .list__item{
    margin-bottom: 30px;
  }
  .list__item:last-child{
    margin-bottom: 0;
  }
  .list__title{
    font-size: 16px;
    font-weight: 700;
  }
  .list__text{
    font-size: 15px;
  }
  .list__image{
    height: 80px;
    max-width: 80px;
    min-width: 80px;
    background-size: 60px;
    margin-right: 30px;
  }
}
</style>