<template>
  <div class="swiper__wrap">
    <swiper
        :modules="modules"
        :space-between="50"
        :loop="true"
        :navigation="{
        prevEl: prev,
        nextEl: next,
      }"
    >
      <swiper-slide
          v-for="project in projects"
          :key="project.id"
          :virtualIndex="project.id"
      >
        <project-item :project="project"/>
      </swiper-slide>
    </swiper>
    <div ref="prev" class="swiper-button swiper-button_prev_full">
      <div class="swiper-button__arrow swiper-button__arrow_prev"></div>
    </div>
    <div ref="next" class="swiper-button swiper-button_next_full">
      <div class="swiper-button__arrow swiper-button__arrow_next"></div>
    </div>
  </div>

</template>

<script>
import { ref } from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation} from "swiper/modules";
import ProjectItem from "@/components/others/ProjectItem";
export default {
  components: {ProjectItem, Swiper, SwiperSlide},
  props: {
    projects: {
      type: Array,
      required: true
    }
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
      modules: [Navigation],
      prev,
      next,
    };
  },
}
</script>

<style scoped>

</style>