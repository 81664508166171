<template>
  <form @submit.prevent="sendForm">
    <name-input id="name-input"/>
    <phone-input id="phone-input"/>
    <the-button type="submit" full>Отправить</the-button>
  </form>
</template>

<script>
import PhoneInput from "@/components/inputs/PhoneInput";
import NameInput from "@/components/inputs/NameInput";
import TheButton from "@/components/buttons/TheButton";
export default {
  components: {TheButton, NameInput, PhoneInput},
  methods: {
    sendForm(event) {
      const form = event.target;
      const formName = form.querySelector('#name-input');
      const formPhone = form.querySelector('#phone-input');

      if(formPhone.value.length < 10) return formPhone.focus();

      this.$emit('sendForm', formPhone.value, formName.value);
      formPhone.value = '';
      formName.value = '';
    }
  }
}
</script>

<style scoped>

</style>