<template>
  <div class="banner" @click="!autoplay && playVideo()">
    <video :src="video + '#t=0.001'" class="banner__video" ref="video" loop muted preload="metadata" :autoplay="autoplay" playsinline></video>
    <div class="banner__play-wrap" v-if="!autoplay">
      <div class="banner__play" ref="playButton"></div>
    </div>
    <div class="banner__content" :style="{backgroundColor: `rgba(0, 0, 0, ${opacity})`}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    video: {
      type: String,
      required: true
    },
    opacity: {
      type: Number,
      required: false,
      default: 0.2
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    playVideo() {
      this.$refs.video.play();
      this.$refs.playButton.style.display = 'none';
    }
  }
}
</script>

<style scoped>
  .banner{
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-color: #f5f5f5;
  }
  .banner__video{
    width: 100%;
  }
  .banner__content{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    padding-top: 60px;
    padding-bottom: 60px;
    z-index: 101;
  }
  .banner__play{
    height: 100px;
    width: 100px;
    border-radius: 100%;
    background-image: url(@/assets/img/play-button.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 102;
    transition: 0.3s;
  }
  .banner__play:hover{
    opacity: 0.7;
    cursor: pointer;
  }
  .banner__play-wrap{
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    .banner{
      min-height: 400px;
    }
    .banner__video{
      min-height: 120%;
      max-height: 120%;
      width: auto;
    }
    .banner__play{
      height: 70px;
      width: 70px;
    }
  }
</style>