<template>
  <div class="navbar">
    <a href="/project" class="navbar__link">Проекты</a>
    <a href="/production" class="navbar__link">Производство</a>
    <a href="/realise" class="navbar__link">Построенные объекты</a>
    <a href="/price" class="navbar__link">Прайс</a>
    <a href="/about" class="navbar__link">О компании</a>
    <a href="/contacts" class="navbar__link">Контакты</a>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.navbar{
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar__link{
  font-size: 15px;
  font-weight: 600;
  color: #2E2E2D;
  margin: 0 12px;
  cursor: pointer;
  transition: 0.3s;
}
.navbar__link:hover{
  color: #66C15E;
}
.navbar__link:first-child{
  margin-left: 0;
}
.navbar__link:last-child{
  margin-right: 0;
}

@media (max-width: 1470px) {
  .navbar__link{
    font-size: 12px;
    margin: 0 6px;
  }
}

@media (max-width: 991px) {
  .navbar{
    display: block;
  }
  .navbar__link:first-child{
    margin-top: 0;
  }
  .navbar__link{
    display: block;
    margin: 25px auto;
    text-align: center;
    font-size: 16px;

  }
}
</style>