<template>
  <div class="text"><slot></slot></div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.text{
  font-size: 18px;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 30px;
}
</style>