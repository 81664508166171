<template>
  <div class="section">
    <video-banner :video="require('@/assets/video/production-clip.mp4')" :poster="require('@/assets/video/production-clip-poster.jpg')" :opacity="0.5" class="production">
      <div class="container production__wrap">
        <div>
          <main-title white>Производство</main-title>
          <main-text white class="production__text">Наше производство находится в г. Киров. У нас происходит полный цикл производства: от добычи сырья до изготовления идеальных материалов, используя современные технологии. Приезжайте – и убедитесь сами!</main-text>
        </div>
        <div class="production__actions">
          <large-button class="production__button" @click="modal = true">Записаться на экскурсию</large-button>
          <a href="/production">
            <link-button>Подробнее о производстве</link-button>
          </a>
        </div>
      </div>
    </video-banner>
  </div>
  <contact-modal title="Записаться на экскурсию" text="Заполните форму, и мы свяжемся с вами для записи" v-model:show="modal"/>
</template>

<script>
import VideoBanner from "@/components/video/VideoBanner";
import MainTitle from "@/components/text/MainTitle";
import MainText from "@/components/text/MainText";
import LargeButton from "@/components/buttons/LargeButton";
import LinkButton from "@/components/buttons/LinkButton";
import ContactModal from "@/components/modals/ContactModal";
export default {
  components: {LinkButton, LargeButton, MainText, MainTitle, VideoBanner, ContactModal},
  data() {
    return {
      modal: false
    }
  }
}
</script>

<style scoped>
  .production{
    height: 700px;
  }
  .production__text{
    width: 1000px;
    margin: 0 auto;
  }
  .production__wrap{
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .production__actions{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .production__button{
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    .production{
      height: 600px;
    }
    .production__text{
      width: 100%;
      font-size: 16px;
    }
  }
</style>