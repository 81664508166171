<template>
	<div class="team-cards">
		<div v-for="member in team" :key="member.name" class="team-card">
			<img :src="member.image" :alt="member.name" class="team-card__image" />
			<h3 class="team-card__name">{{ member.name }}</h3>
			<p class="team-card__role">{{ member.role }}</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		team: {
			type: Array,
			required: true,
		},
	},
}
</script>

<style scoped>
.team-cards {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 20px;
}

.team-card {
	background: #fff;
	border-radius: 12px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	padding: 20px;
	text-align: center;
	width: 20%;
	height: 400px;
	box-sizing: border-box;
}

.team-card__image {
	height: 300px;
	width: 200px;
	border-radius: 15px;
	object-fit: cover;
}

.team-card__name {
	font-size: 18px;
	text-align: center;
	font-weight: 600;
	margin-top: 18px;
}

.team-card__role {
	font-size: 16px;
	font-weight: 500;
	text-align: center;
	margin-top: 8px;
}

@media (max-width: 1024px) {
	.team-card {
		width: calc(50% - 20px);
	}
}

@media (max-width: 768px) {
	.team-card {
		width: calc(50% - 20px);
	}
}

@media (max-width: 480px) {
	.team-card {
		width: 100%;
	}
}
</style>
