<template>
  <div class="params">
    <div class="params__item" v-for="(param, key) in params" :key="key">
      <div class="params__item__num">{{param.num}}</div>
      <div class="params__item__text">{{param.text}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.params{
  display: flex;
  justify-content: center;
}
.params__item{
  padding-right: 45px;
  padding-left: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #22435D;
  border-top: none;
  border-bottom: none;
}
.params__item:first-child{
  margin-left: 0;
  border-left: none;
}
.params__item:last-child{
  margin-right: 0;
  border-right: none;
}
.params__item__num{
  font-size: 55px;
  color: #22435D;
  font-weight: 800;
}
.params__item__text{
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .params{
    display: block;
  }
  .params__item:first-child{
    border-top: 1px solid #22435D;
  }
  .params__item{
    flex-direction: row;
    justify-content: space-between;
    border: none;
    padding: 15px 0;
    border-bottom: 1px solid #22435D;

  }
  .params__item__text{
    order: 1;
  }
  .params__item__num{
    order: 2;
    font-size: 30px;
  }
}
</style>