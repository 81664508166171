<template>
  <div class="section">
    <div class="container">
      <main-title>Производство</main-title>
      <main-text>Наше производство находится в г. Киров. У нас происходит полный цикл производства: от добычи сырья до изготовления идеальных материалов, используя современные технологии. Приезжайте – и убедитесь сами!</main-text>
    </div>
  </div>
  <div class="section">
    <div class="container">
      <div class="production__content">
        <main-title class="production__title__child" white>Клееный брус</main-title>
        <video-banner :video="require('@/assets/video/production2.mp4')" :opacity="0" class="production__video"/>
      </div>
      <content-text class="production__text">
        Клееный брус компании «Барс Дом» изготавливается по передовым технологиям. Чтобы проводить качественную обработку древесины, оборудование поставляют от европейских производителей.
        <br><br>
        В производственных цехах поддерживается необходимый уровень температуры и влажности за счет установленной системы климат-контроля. Влажность древесины также требует постоянной проверки и строгого контроля.
        <br><br>
        Безопасность клеевых составов, применяемых для склеивания ламелей, относится к экологическому классу Е1, который считается наиболее «природным». Процесс склейки ламелей включает раздельное нанесение клея и отвердителя. Оборудование на заводах оснащено ЧПУ, что позволяет при производстве клееного бруса обходиться работой компьютерной программы без участия человека.
        <br><br>
        Для изготовления клееного бруса используется только качественное сырье, а готовый брус проходит неоднократные испытания в лаборатории компании, поэтому наличие брака в партиях домокомплектов практически исключено.
      </content-text>
      <materials-list :list="[
        {image: require('@/assets/img/material1.svg'), text: 'Используем только безопасный и экологичный клей'},
        {image: require('@/assets/img/material2.svg'), text: 'Применяем новейшее оборудование для производства'},
        {image: require('@/assets/img/material3.svg'), text: 'Изготавливаем из разных пород дерева: ель, сосна, кедр, лиственница'},
        {image: require('@/assets/img/material4.svg'), text: 'Уделяем особое внимание сушке, хранению для сохранения качества'},
      ]"/>
    </div>
  </div>
  <div class="section">
    <div class="container">
      <div class="production__content">
        <main-title class="production__title__child" white>Профилированный брус</main-title>
        <video-banner :video="require('@/assets/video/profbrus.mp4')" :opacity="0" class="production__video"/>
      </div>
      <content-text class="production__text">
        Кировский лес уже давно считается практически эталонным за счет особенностей северного климата: древесина этого региона обладает высокой плотностью и прочностью.
        <br><br>
        Дома и бани из профилированного бруса отличаются прекрасным внешним видом, очень быстро возводятся на объекте, при этом они обладают всеми преимуществами домов из натурального дерева: строения из профилированного бруса отлично сохраняют тепло в доме и максимально экологичны.
        <br><br>
        На нашем производстве внедрены современные технологии, которые позволяют с очень высокой производительностью изготавливать профилированный брус высочайшего качества.
        <br><br>
        Наши технологические линии спроектированы для эффективного и точного осуществления всех требуемых проектами домов зарезок стенового бруса максимального сечения 300×300мм и бревна максимального диаметра 300 мм. Наши обрабатывающие центры имеют уникальную производительность — от 250 до 1000 погонных метров бруса в смену.
        <br><br>
        ЧПУ нашего оборудования позволяет использовать стандартные файлы популярных CAD-программ, что позволяет достичь максимальной точности обработки и минимизировать отходы.
        <br><br>
        На заводе производится брус как естественной влажности, так и камерной сушки.
      </content-text>
      <materials-list :list="[
        {image: require('@/assets/img/material5.svg'), text: 'Используем древесину высочайшего качества'},

        {image: require('@/assets/img/material6.svg'), text: 'Изготавливаем брус с различными сеченями и размерами'},
        {image: require('@/assets/img/material2.svg'), text: 'Применяем самые современные технологии'},
        {image: require('@/assets/img/material4.svg'), text: 'Производим брус естественной влажности и камерной сушки'},
      ]"/>
    </div>
  </div>
  <div class="section">
    <div class="container">
      <div class="production__content">
        <main-title class="production__title__child" white>Оцилиндрованное бревно</main-title>
        <video-banner :video="require('@/assets/video/brevno-min.mp4')" :opacity="0" class="production__video"/>
      </div>
      <content-text class="production__text">
        Качество наших домов мы можем с уверенностью гарантировать благодаря использованию качественной древесины собственных лесных хозяйств, современных производственных технологических линий по обработке массива пиловочных бревен в оцилиндрованное бревно, неукоснительным соблюдением всех тонкостей технологии строительства и подготовки материалов.
        <br><br>
        Долговечность последующей эксплуатации наших домов достигается за счет антисептических, лессирующих и огнеупорных средств защиты от ведущих мировых производителей, а сама обработка производится с помощью профессионального современного оборудования. Такие составы сохранят древесину на долгие годы от гниения и плесени, а поскольку древесина сама по себе является легкогорючим материалом, она подвергается также и огнезащитной обработке.
        <br><br>
        Наша компания «Барс Дом» является одним из лучших производителей оцилиндрованного бревна на рынке благодаря качественному сырью. Кировский лес уже давно считается практически эталонным за счет особенностей северного климата: древесина этого региона обладает высокой плотностью и прочностью.
        <br><br>
        На производстве используются самые передовые станки, на выходе из которых мы получаем идеально ровные цилиндры с уже вырезанными монтажными пазами.
      </content-text>
      <materials-list :list="[
        {image: require('@/assets/img/material3.svg'), text: 'Производим надежное и долговечное бревно'},
        {image: require('@/assets/img/material7.svg'), text: 'Применяем огнеупорные средства защиты'},
        {image: require('@/assets/img/material5.svg'), text: 'Изготавливаем только из эталонной древесины'},
        {image: require('@/assets/img/material8.svg'), text: 'Используем самые передовые станки'},
      ]"/>
    </div>
  </div>
</template>

<script>
import AboutProduction from "@/blocks/AboutProduction";
import AboutMaterial from "@/blocks/AboutMaterial";
import AboutTech from "@/blocks/AboutTech";
import MainTitle from "@/components/text/MainTitle";
import MainText from "@/components/text/MainText";
import VideoBanner from "@/components/video/VideoBanner";
import MaterialsList from "@/components/lists/MaterialsList";
import ContentText from "@/components/text/ContentText";
import {useMeta} from "vue-meta";
export default {
  components: {ContentText, MaterialsList, VideoBanner, MainText, MainTitle, AboutTech, AboutMaterial, AboutProduction},
  setup () {
    useMeta({
      title: 'Производство бруса, оцилиндрованного бревна, клееного бруса | Барс Дом',
      description: 'Производство бруса, оцилиндрованного бревна, клееного бруса из сырья высокого качества в Кировской области.',
    })
  }
}
</script>

<style scoped>
.production__video{
  height: 600px;
}
.production__content{
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 30px;
  border-radius: 7px;
  overflow: hidden;
}
.production__title__child{
  margin-bottom: 30px;
}
.production__text{
  margin-top: 45px;
  margin-bottom: 45px;
}

@media (max-width: 768px) {
  .production__video{
    height: 300px;
    min-height: 300px !important;
  }
  .production__text{
    font-size: 16px;
  }
}
</style>