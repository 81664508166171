import axios from "axios";
import data from "@/data";

export async function getProjects() {
    try {
        const result = await axios.post(data.apiUrl, {
            action: 'getProjects'
        })

        return result.data.map((p) => {
            return {
                id: p.id,
                image: p.images[0],
                name: p.name,
                params: [
                    'Площадь: ' + p.square,
                    'Комнат: ' + p.rooms,
                    'Санузлов: ' + p.baths
                ]
            }
        });
    } catch (e) {
        console.log(e);
        return [];
    }
}
export async function getProjectById(id) {
    try {
        const result = await axios.post(data.apiUrl, {
            action: 'getProjectById',
            id: id
        })
        return result.data;
    } catch (e) {
        console.log(e);
        return {};
    }
}
export async function getRealisedProjects() {
    try {
        const result = await axios.post(data.apiUrl, {
            action: 'getRealisedProjects'
        })
        return result.data;
    } catch (e) {
        console.log(e);
        return [];
    }
}
export async function getRealisedProjectById(id) {
    try {
        const result = await axios.post(data.apiUrl, {
            action: 'getRealisedProjectById',
            id: id
        })
        return result.data;
    } catch (e) {
        console.log(e);
        return {};
    }
}

export async function removeRealisedImage(id) {
    try {
        const result = await axios.post(data.apiUrl, {
            action: 'removeRealisedImage',
            id: id
        })
        return result.data;
    } catch (e) {
        console.log(e);
        return {};
    }
}

export function sendForm(phone, name, action, url) {
    let message = "Заявка с сайта:\nДействие: " + action;

    if(name && name !== '') message += "\nИмя: " + name;
    message += "\nТелефон: " + phone;
    message += "\nСтраница: " + url

    return sendTelegramRequest(message);
}

async function sendTelegramRequest(message){
    const telegramToken = '6882387729:AAES2_3eI5cdFsrBcqKiOhsSrF6ojqCorOg';
    //const chatId = '595036300';
    const chatId = '6784063001';
    const telegramUrl = 'https://api.telegram.org/bot' + telegramToken + '/sendMessage';
    try {
        return await axios.get(
            telegramUrl,
            {
                params: {
                    chat_id: chatId,
                    text: message
                }
            }
        );
    } catch (e) {
        console.log(e);
        return false;
    }

}

export async function addRealised(type, name, images){
    const formData = new FormData();
    formData.append('action', 'addRealised');
    formData.append('type', type);
    formData.append('name', name);

    for( var i = 0; i < images.length; i++ ){
        const file = images[i];
        formData.append('files[' + i + ']', file);
    }

    try {
        return await axios.post(
            data.apiUrl,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    } catch (e) {
        console.log(e);
        return false;
    }
}