<template>
  <div class="ask">
    <div class="container">
      <div class="ask__content">
        <div>
          <main-title align="left" white class="ask__title">{{title}}</main-title>
          <main-text align="left" left white class="ask__text">{{text}}</main-text>
        </div>
        <biggest-button @click="modal = true" class="ask__button">{{buttonText}}</biggest-button>
      </div>
    </div>
  </div>
  <contact-modal :title="buttonText" :text="text" v-model:show="modal"/>
</template>

<script>
import MainTitle from "@/components/text/MainTitle";
import MainText from "@/components/text/MainText";
import BiggestButton from "@/components/buttons/BiggestButton";
import ContactModal from "@/components/modals/ContactModal";
export default {
  components: {BiggestButton, MainText, MainTitle, ContactModal},
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Оставить заявку'
    }
  },
  data() {
    return {
      modal: false
    }
  }
}
</script>

<style scoped>
.ask{
  padding: 80px 0;
  margin: 60px 0;
  background-color: #22435D;
}
.ask__content{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ask__title{
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .ask{
    margin-top: 0;
  }
  .ask__content{
    display: block;
  }
  .ask__title{
    font-size: 20px;
  }
  .ask__text{
    margin-bottom: 30px;
  }
  .ask__button{
    width: 100%;
  }
}
</style>