<template>
  <div class="section about">
    <div class="container">
      <main-title class="about__title">Cобственное производство – <br>гарантия качества вашего дома</main-title>
      <div class="section__row about__row1">
        <div class="section__column">
<!--          <the-image :url="require('@/assets/img/about-tech.jpg')" :height="500" :contain="true"/>-->
          <div class="section__row about__row">
            <div class="section__column about__column">
              <the-image class="about__img" :url="require('@/assets/img/about-tech1.jpg')" />
            </div>
            <div class="section__column about__column">
              <the-image class="about__img" :url="require('@/assets/img/about-tech2.jpg')" />
            </div>
          </div>
          <div class="section__row">
            <div class="section__column">
              <the-image class="about__img" :url="require('@/assets/img/about-tech3.jpg')" />
            </div>
          </div>
        </div>
        <div class="section__column about__content">
          <div>
            <main-text align="left" class="about__text">
              Наша компания имеет собственное производство, благодаря чему мы можем можем гарантировать надежный дом, так как сами отвечаем за качество наших материалов
            </main-text>
            <dotted-list
                :list="
                [
                  'Бережное хранение и транспортировка',
                  'Не экономим на сырье и оборудовании',
                  'Современные технологии производства',
                  'Различные породы дерева: ель, сосна, кедр, лиственница'
                ]"
            />
          </div>
          <div class="about__actions">
            <large-button class="about__button" @click="modal = true">Записаться на экскурсию</large-button>
            <the-notice class="about__notice"><i>Убедитесь сами</i> – приезжайте на экскурсию на производство</the-notice>
          </div>
        </div>
      </div>
    </div>
  </div>
  <contact-modal title="Записаться на экскурсию" text="Заполните форму, и мы свяжемся с вами для записи" v-model:show="modal"/>
</template>

<script>
import TheImage from "@/components/images/TheImage";
import DottedList from "@/components/lists/DottedList";
import MainTitle from "@/components/text/MainTitle";
import MainText from "@/components/text/MainText";
import LargeButton from "@/components/buttons/LargeButton";
import TheNotice from "@/components/text/TheNotice";
import ModalTitle from "@/components/text/ModalTitle";
import ModalText from "@/components/text/ModalText";
import ContactForm from "@/components/forms/ContactForm";
import ContactModal from "@/components/modals/ContactModal";
export default {
  components: {
    ContactModal,
    ContactForm,
    ModalText, ModalTitle, TheNotice, LargeButton, MainText, MainTitle, DottedList, TheImage
  },
  data() {
    return {
      modal: false
    }
  }
}
</script>

<style scoped>
  .about__image{
    height: 500px;
  }
  .about__text{
    margin-bottom: 80px;
  }
  .about__actions{
    display: flex;
    align-items: center;
    margin-top: 50px;
  }
  .about__button{
    min-width: 290px;
    margin-right: 30px;
  }
  .about__column{
    margin-right: 10px;
    margin-left: 10px;
  }
  .about__column:first-child{
    margin-left: 0;
  }
  .about__column:last-child{
    margin-right: 0;
  }
  .about__row{
    margin-bottom: 10px;
  }
  .about__content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    .about__title{
      font-size: 18px;
    }
    .about__title br{
      display: none;
    }
    .about__row1{
      display: block;
    }
    .about__content{
      margin-left: 0;
    }
    .about__column{
      margin: 0 5px;
    }
    .about__text{
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .about__actions{
      display: block;
    }
    .about__button{
      margin: 0 auto 20px;
    }
    .about__notice{
      text-align: center;
    }
  }
</style>
<style>
.about__img img{
  height: 300px !important;
}
@media (max-width: 768px) {
  .about__img img{
    height: 200px !important;
  }
}
</style>