<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : 'Строительство деревянных домов | Барс Дом' }}</template>
  </metainfo>
  <second-header/>
  <router-view/>
  <another-contacts/>
  <the-footer/>
  <flying-button/>
</template>

<style>
@import url(@/assets/css/reset.css);
@import url(@/assets/fonts/TTTravels/stylesheet.css);
@import url(@/assets/css/MainStyle.css);
</style>
<script>
import TheHeader from "@/layouts/TheHeader";
import TheContacts from "@/blocks/TheContacts";
import SecondHeader from "@/layouts/SecondHeader";
import TheFooter from "@/layouts/TheFooter";
import FlyingButton from "@/components/buttons/FlyingButton";
import AnotherContacts from "@/blocks/AnotherContacts";
export default {
  components: {AnotherContacts, FlyingButton, TheFooter, SecondHeader, TheContacts,TheHeader},
  mounted() {
    // const videoList = document.querySelectorAll('video');
    // let currentIndex = 0;
    // [...videoList].forEach((video) => {
    //   video.addEventListener('onloadend', (event) => {
    //     currentIndex++;
    //     if(currentIndex < videoList.length) videoList[currentIndex].play();
    //   })
    // })
    // videoList[currentIndex].play();
  }
}
</script>