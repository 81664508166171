<template>
  <div class="callback">
    <div class="callback__title">Заинтересовал этот проект?</div>
    <large-button full @click="modal = true">Заказать расчет стоимости</large-button>
  </div>
  <ContactModal title="Заинтересовал этот проект?" text="Заполните форму, и мы пришлем вам смету по данному проекту" v-model:show="modal"/>
</template>

<script>
import LargeButton from "@/components/buttons/LargeButton";
import ContactModal from "@/components/modals/ContactModal";
export default {
  components: {ContactModal, LargeButton},
  data() {
    return {
      modal: false
    }
  }
}
</script>

<style scoped>
  .callback{
    border-radius: 7px;
    background-color: #22435D;
    padding: 30px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .callback__title{
    font-size: 28px;
    font-weight: 600;
    color: #fff;
  }
</style>