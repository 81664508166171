<template>
  <video-banner
      class="fblock"
      :opacity="0"
      :video="require('@/assets/video/banner-finally-min.mp4')"
      autoplay
  >
    <div class="fblock__title__container">
      <div class="fblock__title">Производство и строительство домов из дерева</div>
    </div>
  </video-banner>
  <div class="section">
    <div class="container">
      <adv-list
          :list="[
             {
                image: require('@/assets/img/advantage4.svg'),
                title: 'Проектирование',
                text: 'Проектируем дома с удобными планировками и грамотным использованием площади'
              },
              {
                image: require('@/assets/img/advantage1.svg'),
                title: 'Производство',
                text: 'Производим материалы высокого качества из отборного дерева для надежности вашего дома'
              },
              {
                image: require('@/assets/img/advantage2.svg'),
                title: 'Строительство',
                text: 'Берем на себя все этапы работ: от проекта до полного благоустройства дома'
              }
            ]"
      />
    </div>
  </div>
</template>

<script>
import VideoBanner from "@/components/video/VideoBanner";
import AdvList from "@/components/lists/AdvList";
export default {
  components: {AdvList, VideoBanner}
}
</script>

<style scoped>
.fblock{
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 750px;
}
.fblock__title__container{
  display: flex;
}
.fblock__title{
  font-size: 40px;
  line-height: 1;
  color: #fff;
  font-weight: 800;
  text-align: center;
  margin: 120px auto 0;
  text-transform: uppercase;
  width: auto;
  padding: 20px 30px;
  border-radius: 10px;
  display: block;
  background-color: rgba(0, 0, 0, 0.6);
}
.section{
  padding-top: 15px;
}

@media (max-width: 768px) {
  .fblock{
    height: 400px;
  }
  .fblock__title{
    font-size: 22px;
    line-height: 1.3;
    padding: 10px;
    margin-top: 30px;
    border-radius: 0;
  }
}
</style>