<template>
  <div class="section contacts">
    <div class="container">
      <main-title>Контакты</main-title>
      <div class="section__row">
        <div class="section__column">
          <iframe class="contacts__frame" :src="c.officeAddressLink"/>
        </div>
        <div class="section__column">
          <div class="contacts__content">
            <div class="contacts__item">
              <div class="contacts__param">Адрес офиса</div>
              <div class="contacts__value">{{c.officeAddress}}</div>
            </div>
            <div class="contacts__item">
              <div class="contacts__param">Телефон</div>
              <a :href="c.firstPhoneLink" class="contacts__value phone">{{c.firstPhone}}</a>
              <a :href="c.secondPhoneLink" class="contacts__value">{{c.secondPhone}}</a>
            </div>
            <div class="contacts__item">
              <div class="contacts__param">Email</div>
              <a :href="'mailto: ' + c.email" class="contacts__value">{{c.email}}</a>
            </div>
            <large-button
                class="contacts__button"
                @click="this.modal.title = 'Заказать звонок'; this.modal.text = 'Заполните форму, и мы свяжемся с вами в ближайшее время'; this.modal.show = true"
            >
              Заказать звонок
            </large-button>
          </div>
        </div>
      </div>
      <div class="contacts__divider"></div>
      <div class="section__row">
        <div class="section__column">
          <iframe class="contacts__frame" :src="c.productionAddressLink"/>
        </div>
        <div class="section__column">
          <div class="contacts__content contacts__flex-column">
            <div class="contacts__top">
              <div class="contacts__item">
                <div class="contacts__param">Адрес производства</div>
                <div class="contacts__value">{{c.productionAddress}}</div>
              </div>
              <div class="contacts__item">
                <div class="contacts__param"></div>
                <div class="contacts__value">Посещение по предварительной записи</div>
              </div>
            </div>
            <div class="contacts__bottom">
              <large-button class="contacts__button" @click="this.modal.title = 'Записаться на экскурсию'; this.modal.text = 'Заполните форму, и мы свяжемся с вами для записи'; this.modal.show = true">
                Записаться на экскурсию
              </large-button>
              <a href="/partner.pdf">
                <large-button class="contacts__button" style="margin-top: 30px;" @click="window.location.href=''">
                Карта партнера
                </large-button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <contact-modal :title="modal.title" :text="modal.text" v-model:show="modal.show"/>
</template>

<script>
import data from "@/data";
import LargeButton from "@/components/buttons/LargeButton";
import ContactModal from "@/components/modals/ContactModal";
import MainTitle from "@/components/text/MainTitle";
export default {
  components: {MainTitle, LargeButton, ContactModal},
  data() {
    return {
      c: data.contacts,
      modal: {
        title: '',
        text: '',
        show: false
      },
    }
  }
}
</script>

<style scoped>
.contacts__item{
  margin-bottom: 35px;
}
.contacts__param{
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.contacts__value{
  font-size: 22px;
  display: block;
  color: #000;
  font-weight: 500;
  margin-bottom: 10px;
  transition: 0.3s;
}
.contacts__value.phone{
  letter-spacing: 0.08rem;
}
.contacts__value:last-child{
  margin-bottom: 0;
}
a.contacts__value:hover{
  color: #66C15E;
}
.contacts__frame{
  height: 400px;
  width: 100%;
  border-radius: 7px;
}

.contacts__content{
  margin-left: 60px;
}

.contacts__button{
  width: 310px;
}

.contacts__divider{
  height: 2px;
  background-color: #efefef;
  margin-top: 30px;
  margin-bottom: 30px;
}

.contacts__flex-column{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

@media (max-width: 768px) {
  .section__column{
    margin: 0;
  }
  .section__column:first-child{
    order: 2;
  }
  .section__column:last-child{
    order: 1;
  }
  .section__row{
    flex-direction: column;
  }
  .contacts__content{
    margin-left: 0;
  }
  .contacts__button{
    width: 100%;
    margin-bottom: 20px;
  }
  .contacts__frame{
    height: 300px;
  }
  .contacts__param{
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
  .contacts__value{
    font-size: 20px;
    text-align: center;
  }
  .contacts__value.phone{
    letter-spacing: 0.076rem;
  }
}
</style>