<template>
  <div class="footer">
    <div class="container footer__wrap">
      <div class="footer__text">«Барс Дом» &copy; 2023 | Все права защищены</div>
      <div class="footer__text">Разработано в <a class="footer__link" href="https://digital-quality.ru" rel="nofollow" target="_blank">Digital Quality</a></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.footer{
  background-color: #000;
  padding: 45px 0;
}
.footer__wrap{
  display: flex;
  justify-content: space-between;
}
.footer__text{
  font-size: 16px;
  color: #fff;
}
.footer__link{
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #fff;
  transition: 0.3s;
}
.footer__link:hover{
  border-color: transparent;
}

@media (max-width: 768px) {
  .footer__wrap{
    flex-direction: column;
  }
  .footer__text{
    text-align: center;
    margin: 10px 0;
  }
}
</style>