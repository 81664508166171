<template>
  <div class="header">
    <div class="container header__wrap">
      <the-logo class="header__logo"/>
      <inline-navbar class="header__navbar"/>
      <div class="header__actions">
        <social-button :link="c.whatsappLink" class="whatsapp"/>
        <social-button :link="c.telegramLink" class="telegram"/>
        <social-button :link="c.instagramLink" class="instagram"/>
        <div class="header__phones">
          <a :href="c.firstPhoneLink" class="header__phone">{{c.firstPhone}}</a>
          <a :href="c.secondPhoneLink" class="header__phone">{{ c.secondPhone }}</a>
        </div>
      </div>
      <div class="header__menu-button" @click="showMenu = !showMenu">
        <div class="header__menu-button__image header__menu-button__image_open" v-show="!showMenu"></div>
        <div class="header__menu-button__image header__menu-button__image_close" v-show="showMenu"></div>
      </div>
    </div>
  </div>
  <div class="under-header"></div>
  <transition name="fade">
    <div class="header__menu" v-show="showMenu">
      <inline-navbar class="header__menu__navbar"/>
      <div class="header__menu__social">
        <social-button :link="c.whatsappLink" class="whatsapp"/>
        <social-button :link="c.telegramLink" class="telegram"/>
        <social-button :link="c.instagramLink" class="instagram"/>
      </div>
      <div class="header__menu__phones">
        <a :href="c.firstPhoneLink" class="header__menu__phone">{{c.firstPhone}}</a>
        <a :href="c.secondPhoneLink" class="header__menu__phone">{{ c.secondPhone }}</a>
      </div>
    </div>
  </transition>
</template>

<script>
import TheLogo from "@/components/images/TheLogo";
import InlineNavbar from "@/layouts/InlineNavbar";
import SocialButton from "@/components/buttons/SocialButton";
import TheButton from "@/components/buttons/TheButton";
import data from "@/data";
export default {
  components: {InlineNavbar, TheLogo, SocialButton, TheButton},
  data() {
    return {
      c: data.contacts,
      showMenu: false,
    }
  },
}
</script>

<style scoped>

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.header{
  position: fixed;
  width: 100%;
  z-index: 1001;
  top: 0;
  background-color: #fff;
  padding: 13px 0;
  border-bottom: 2px solid #D9D9D9;
}
.header__logo{
  margin-top: -5px;
}
.header__wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__actions{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.under-header{
  height: 80px;
}

.header__phones{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.header__phone{
  font-size: 20px;
  font-weight: 700;
  color: #2E2E2D;
  cursor: pointer;
  transition: 0.3s;
}
.header__phone:hover{
  color: #66C15E;
}
.header__phone:first-child{
  margin-bottom: 3px;
  letter-spacing: 0.063rem;
}
.header__menu-button{
  display: none;
}

@media (max-width: 991px) {
  .header{
    padding: 9px 0;
    height: 67px;
  }
  .under-header{
    height: 67px;
  }
  .header__actions{
    display: none;
  }
  .header__navbar{
    display: none;
  }
  .header__menu-button__image{
    height: 47px;
    width: 47px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .header__menu-button{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header__menu-button__image_close{
    background-image: url('@/assets/img/close-menu.svg');
  }
  .header__menu-button__image_open{
    background-image: url('@/assets/img/open-menu.svg');
  }
  .header__menu{
    z-index: 1001;
    position: fixed;
    top: 67px;
    width: 100%;
    padding: 20px;
    background-color: #f5f5f5;
    border-bottom: 2px solid #D9D9D9;
  }
  .header__menu__social{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .header__menu__phone{
    font-size: 20px;
    color: #000;
    font-weight: 600;
    display: block;
    margin: 10px auto;
    text-align: center;
  }
  .header__menu__phone:first-child{
    letter-spacing: 0.069rem;
  }
}
</style>