<template>
  <div class="swiper__wrap">
    <swiper
        :modules="modules"
        :space-between="50"
        :loop="true"
        :slides-per-view="1.5"
        :breakpoints="{ 300:{ slidesPerView: 1 }, 900:{ slidesPerView: 1.5 } }"
        :centered-slides="true"
        :navigation="{
        prevEl: prev,
        nextEl: next,
      }"
    >
      <swiper-slide
          v-for="(image, key) in images"
          :key="key"
          :virtualIndex="key"
      >
        <the-image :url="image" :contain="contain" class="project-img"/>
      </swiper-slide>
    </swiper>
    <div ref="prev" class="swiper-button swiper-button_prev_full">
      <div class="swiper-button__arrow swiper-button__arrow_prev"></div>
    </div>
    <div ref="next" class="swiper-button swiper-button_next_full">
      <div class="swiper-button__arrow swiper-button__arrow_next"></div>
    </div>
  </div>

</template>

<script>
import { ref } from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation} from "swiper/modules";
import ProjectItem from "@/components/others/ProjectItem";
import TheImage from "@/components/images/TheImage";
import TheButton from "@/components/buttons/TheButton";
import {removeRealisedImage} from "@/api";
export default {
  components: {TheButton, TheImage, Swiper, SwiperSlide},
  props: {
    images: {
      type: Array,
      required: true
    },
    contain: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    async removeImage(id) {
      const result = await removeRealisedImage(id);
      console.log(result);
    }
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
      modules: [Navigation],
      prev,
      next,
    };
  },
}
</script>

<style scoped>
.project-img{
  background-color: #000;
  height: 600px;
  border-radius: 7px;
}
@media (max-width: 768px) {
  .swiper__wrap{
    margin-right: 10px;
    margin-left: 10px;
  }
  .project-img{
    height: 300px;
  }
}
</style>

<style>
.project-img img{
  min-height: 100%;
}
@media (max-width: 768px) {
  .realised_carousel .project-img{
    height: 500px;
  }
  .realised_carousel img{
    height: 500px !important;
  }
}
</style>