<template>
  <div class="section contacts">
    <div class="container">
      <main-title>Контакты</main-title>
      <main-text class="contacts__text">В офисе мы сможем проконсультировать вас, подобрать подходящие проекты. На производстве мы покажем Вам процесс изготовления материалов для строительства</main-text>
    </div>
    <div class="contacts__frame-wrap">
      <iframe class="contacts__iframe" :src="data.contacts.officeAddressLink" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe>
      <div class="contacts__content">
        <div class="contacts__item">
          <div class="contacts__param">Адрес офиса</div>
          <div class="contacts__value">{{data.contacts.officeAddress}}</div>
        </div>
        <div class="contacts__item">
          <div class="contacts__param">Телефон</div>
          <div class="contacts__values">
            <a :href="data.contacts.firstPhoneLink" class="contacts__value phone">{{data.contacts.firstPhone}}</a>
            <a :href="data.contacts.secondPhoneLink" class="contacts__value">{{data.contacts.secondPhone}}</a>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contacts__param">Email</div>
          <a :href="'mailto:' + data.contacts.email" class="contacts__value">{{data.contacts.email}}</a>
        </div>
        <the-button
            class="contacts__button"
            @click="this.modal.title = 'Заказать звонок'; this.modal.text = 'Заполните форму, и мы свяжемся с вами в ближайшее время'; this.modal.show = true"
        >
          Заказать звонок
        </the-button>
      </div>
    </div>
    <div class="contacts__frame-wrap">
      <iframe class="contacts__iframe" :src="data.contacts.productionAddressLink" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe>
      <div class="contacts__content contacts__content_right">
        <div class="contacts__item">
          <div class="contacts__param">Адрес производства</div>
          <div class="contacts__value">{{data.contacts.productionAddress}}</div>
        </div>
        <div class="contacts__item">
          <div class="contacts__param"></div>
          <div class="contacts__values">
            <div class="contacts__value"></div>
            <div class="contacts__value"></div>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contacts__param"></div>
          <div class="contacts__value">(посещение по предварительной записи)</div>
        </div>
        <the-button
            class="contacts__button"
            @click="this.modal.title = 'Записаться на экскурсию'; this.modal.text = 'Заполните форму, и мы свяжемся с вами для записи'; this.modal.show = true"
        >
          Записаться на экскурсию
        </the-button>
      </div>
    </div>
  </div>
  <contact-modal :title="modal.title" :text="modal.text" v-model:show="modal.show"/>
</template>

<script>
import MainTitle from "@/components/text/MainTitle";
import MainText from "@/components/text/MainText";
import TheButton from "@/components/buttons/TheButton";
import ContactModal from "@/components/modals/ContactModal";
import data from "@/data";
export default {
  components: {TheButton, MainText, MainTitle, ContactModal},
  data() {
    return {
      modal: {
        title: '',
        text: '',
        show: false
      },
      data: data
    }
  }
}
</script>

<style scoped>
  .contacts{
    padding-bottom: 0;
  }
  .contacts__text{
    width: 1100px;
    margin: 0 auto 60px;
  }
  .contacts__frame-wrap{
    position: relative;
    margin-bottom: 30px;
  }
  .contacts__frame-wrap:last-child{
    margin-bottom: 0;
  }
  .contacts__iframe{
    height: 500px;
    width: 100%;
  }
  .contacts__content{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 600px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 80px 100px;
  }
  .contacts__content_right{
    right: 0;
    left: auto;
  }
  .contacts__item{
    margin-bottom: 30px;
  }
  .contacts__param{
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 15px;
    color: #fff;
  }
  .contacts__values{
    display: flex;
  }
  .contacts__value{
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin-right: 22px;
    transition: 0.3s;
  }
  .contacts__value.phone{
    letter-spacing: 0.06rem;
  }
  a.contacts__value:hover{
    color: #66C15E;
  }
  .contacts__value:last-child{
    margin-right: 0;
  }
  .contacts__button{
    margin-top: 60px;
    width: 310px;
  }
  
  @media (max-width: 768px) {

  }
</style>