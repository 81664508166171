<template>
  <div class="list">
    <div class="list__item" v-for="(item, key) in list" :key="key">
      <about-material-item :item="item"/>
    </div>
  </div>
</template>

<script>
import AboutMaterialItem from "@/components/others/AboutMaterialItem";
export default {
  components: {AboutMaterialItem},
  props: {
    list: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.list{
  display: flex;
  flex-wrap: wrap;
}
.list__item{
  width: 45%;
  margin-right: 5%;
}
@media (max-width: 768px) {
  .list{
    flex-direction: column;
  }
  .list__item{
    width: 100%;
    margin-right: 0;
  }
}
</style>