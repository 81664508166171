<template>
  <div class="section">
    <main-title>{{title}}</main-title>
    <realised-list-carousel :projects="projects"/>
  </div>
</template>

<script>
import MainTitle from "@/components/text/MainTitle";
import {getRealisedProjects} from "@/api";
import RealisedListCarousel from "@/components/carousel/RealisedListCarousel";
export default {
  components: {RealisedListCarousel, MainTitle},
  data() {
    return {
      projects: []
    }
  },
  async mounted() {
    this.projects = await getRealisedProjects();
  },
  props: {
    title: {
      type: String,
      default: 'Построенные объекты',
      required: false
    }
  }
}
</script>

<style scoped>

</style>