<template>
  <button class="button" :class="{'button_shadow': shadow, 'button_full': full}">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    shadow: {
      type: Boolean,
      default: true,
      required: false
    },
    full: {
      type: Boolean,
      default: false,
      required: false
    }
  }
}
</script>

<style scoped>
  .button{
    font-size: 18px;
    font-weight: 600;
    padding: 15px 20px;
    background-color: #66C15E;
    border-radius: 7px;
    color: #fff;
    transition: 0.3s;
    height: 50px;
    display: block;
  }
  .button:hover{
    /*background-color: #748504;*/
    opacity: 0.8;
  }
  .button_shadow{
    box-shadow: 0px 4px 10px 0px #66C15E;
  }
  .button_full{
    width: 100%;
  }
</style>