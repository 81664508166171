<template>
  <div class="section materials">
    <div class="container">
      <main-title>Качество нашего материала</main-title>
      <materials-list :list="items"/>
<!--      <large-outline-button class="materials__button">Подробнее о брусе</large-outline-button>-->
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/text/MainTitle";
import AboutMaterialItem from "@/components/others/AboutMaterialItem";
import LargeButton from "@/components/buttons/LargeButton";
import LargeOutlineButton from "@/components/buttons/LargeOutlineButton";
import MaterialsList from "@/components/lists/MaterialsList";
export default {
  components: {MaterialsList, LargeOutlineButton, LargeButton, AboutMaterialItem, MainTitle},
  data() {
    return {
      items: [
        {image: require('@/assets/img/material1.svg'), text: 'Используем только безопасный и экологичный клей'},
        {image: require('@/assets/img/material2.svg'), text: 'Применяем новейшее оборудование для производства'},
        {image: require('@/assets/img/material3.svg'), text: 'Изготавливаем из разных пород дерева: ель, сосна, кедр, лиственница'},
        {image: require('@/assets/img/material4.svg'), text: 'Уделяем особое внимание сушке, хранению для сохранения качества'},
      ],
    }
  }
}
</script>

<style scoped>
  .materials__wrap{
    display: flex;
    flex-wrap: wrap;
  }
  .materials__button{
    width: 320px;
    margin: 100px auto 0;
  }
</style>