import { createRouter, createWebHistory } from 'vue-router'
import TheMain from "@/pages/TheMain";
import ProjectPage from "@/pages/ProjectPage";
import ProjectsList from "@/pages/ProjectsList";
import RealisedPage from "@/pages/RealisedPage";
import TheProduction from "@/pages/TheProduction";
import RealisedList from "@/pages/RealisedList";
import AboutCompany from "@/pages/AboutCompany";
import PriceList from "@/pages/PriceList";
import TheContacts from "@/pages/TheContacts";

const routes = [
  {
    path: '/',
    name: 'main',
    component: TheMain
  },
  {
    path: '/project/:id',
    name: 'project',
    component: ProjectPage
  },
  {
    path: '/project/',
    name: 'projectsList',
    component: ProjectsList
  },
  {
    path: '/realised/:id',
    name: 'realised',
    component: RealisedPage
  },
  {
    path: '/production/',
    name: 'production',
    component: TheProduction
  },
  {
    path: '/realise/',
    name: 'realisedList',
    component: RealisedList
  },
  {
    path: '/about',
    name: 'aboutCompany',
    component: AboutCompany
  },
  {
    path: '/price',
    name: 'priceList',
    component: PriceList
  },
  {
    path: '/contacts',
    name: 'TheContacts',
    component: TheContacts
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
