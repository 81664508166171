<template>
  <div class="stat">
    <div class="stat__num">{{stat.num}}</div>
    <div class="stat__text" v-html="stat.text"></div>
  </div>
</template>

<script>
export default {
  props: {
    stat: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
  .stat{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .stat__num{
    font-size: 80px;
    font-weight: 800;
    color: #66C15E;
    text-shadow: 0px 0px 7px #66C15E;
    text-align: center;
  }
  .stat__text{
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
  }

  @media (max-width: 768px) {
    .stat{
      width: 50%;
      margin-bottom: 30px;
    }
    .stat__num{
      font-size: 56px;
    }
    .stat__text{
      font-size: 16px;
    }
  }
</style>