<template>
  <base-input placeholder="Введите имя" maxlength="20"/>
</template>

<script>
import BaseInput from "@/components/inputs/BaseInput";
export default {
  components: {BaseInput}
}
</script>

<style scoped>

</style>